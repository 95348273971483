const vendorFormFields = {
	section1: [
		[
			{
				label: 'Mobile Number',
				colSpan: '12',
				isRequired: true,
				id: 'mobile_number',
				type: 'text',
			}
		],
		[
			{
				label: 'Company Name',
				colSpan: '8',
				isRequired: true,
				id: 'company_name',
				type: 'text',
			},
			{
				label: 'Mailing Name',
				colSpan: '8',
				isRequired: false,
                disabled: true,
				id: 'mailing_name',
				type: 'text',
			},
			{
				label: 'Opening Weight',
				colSpan: '6',
				isRequired: false,
				id: 'pending_weight',
				type: 'text',
			},
			{
				label: 'unit',
				colSpan: '2',
				isRequired: false,
				id: 'pending_weight_unit',
				type: 'select',
			},
			// {
			// 	label:'Advance paid Weight',
			// 	colSpan: '5',
			// 	isRequired: false,
			// 	id: 'advance_weight',
			// 	type: 'text',
			// },
			// {
			// 	label: 'unit',
			// 	colSpan: '2',
			// 	isRequired: false,
			// 	id: 'advance_weight_unit',
			// 	type: 'select',
			// },
		],
		[
			{
				label: 'Address',
				colSpan: '6',
				isRequired: false,
				id: 'address',
				type: 'text',
			},
			{
				label: 'Country',
				colSpan: '6',
				isRequired: false,
				id: 'country',
				type: 'text',
			},
			{
				label: 'State',
				colSpan: '6',
				isRequired: false,
				id: 'state',
				type: 'text',
			},
			{
				label: 'City',
				colSpan: '6',
				isRequired: false,
				id: 'city',
				type: 'text',
			},           
		],
        [
            {
				label: 'Zip Code',
				colSpan: '6',
				isRequired: false,
				id: 'zip_code',
				type: 'text',
			},
			{
				label: 'Website',
				colSpan: '6',
				isRequired: false,
				id: 'website',
				type: 'text',
			},
			{
				label: 'PAN Card Number',
				colSpan: '6',
				isRequired: false,
				id: 'pan_card_no',
				type: 'text',
			},
            {
				label: 'GST Register Type',
				colSpan: '6',
				isRequired: false,
				id: 'gst_register_type',
				type: 'text',
			},
        ],
        [
            {
				label: 'GST No',
				colSpan: '12',
				isRequired: false,
				id: 'gst_no',
				type: 'text',
			},
            {
				label: 'Email',
				colSpan: '12',
				isRequired: false,
				id: 'email',
				type: 'text',
			}
        ],
        [
            {
				label: 'Phone Number',
				colSpan: '8',
				isRequired: false,
				id: 'phone_number',
				type: 'text',
			},
            {
				label: 'Credit Days',
				colSpan: '8',
				isRequired: false,
				id: 'credit_days',
				type: 'text',
			},
            {
				label: 'Vendor Job Type',
				colSpan: '8',
				isRequired: false,
				id: 'vendor_job_type',
				type: 'text',
			},
        ]
	],
	section2: [
		[
			{
				label: 'Contact Name',
				colSpan: '6',
				isRequired: false,
				id: 'contact_name',
				type: 'text',
			},
			{
				label: 'Designation',
				colSpan: '6',
				isRequired: false,
				id: 'designation',
				type: 'text',
			},
            {
				label: 'Department',
				colSpan: '6',
				isRequired: false,
				id: 'department',
				type: 'text',
			},
			{
				label: 'Mobile Number',
				colSpan: '6',
				isRequired: false,
				id: 'contact_mobile_number',
				type: 'text',
			},
		],
		[
			{
				label: 'Email',
				colSpan: '12',
				isRequired: false,
				id: 'contact_email',
				type: 'text',
			},
			{
				label: 'Experience',
				colSpan: '6',
				isRequired: false,
				id: 'experience',
				type: 'text',
			},
            {
				label: 'Education Details',
				colSpan: '6',
				isRequired: false,
				id: 'education_details',
				type: 'text',
			},
		],
	],
	section3: [
        [
			{
				label: 'Account Holder Name',
				colSpan: '12',
				isRequired: false,
				id: 'account_holder_name',
				type: 'text',
			},
			{
				label: 'Account Number',
				colSpan: '6',
				isRequired: false,
				id: 'account_no',
				type: 'text',
			},
            {
				label: 'Confirm Account Number',
				colSpan: '6',
				isRequired: false,
				id: 'confirm_account_no',
				type: 'text',
			}
		],
		[
			{
				label: 'IFSC/SWIFT Code',
				colSpan: '8',
				isRequired: false,
				id: 'ifsc_code',
				type: 'text',
			},
			{
				label: 'Bank Name',
				colSpan: '8',
				isRequired: false,
				id: 'bank_name',
				type: 'text',
			},
            {
				label: 'Branch',
				colSpan: '8',
				isRequired: false,
				id: 'branch',
				type: 'text',
			},
		],
	],
};

export default vendorFormFields;