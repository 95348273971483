import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Space, Spin, Table, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import CostCenterImage from '../../assets/images/cost-center.svg';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api'; // Axios or Fetch wrapper
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FilterFilled } from '@ant-design/icons';

const CostCenterList = () => {
  const navigate = useNavigate();
  const searchInput = useRef(null);
  const [costCenterData, setCostCenterData] = useState([]);
  const tableColumnSearch = columnSearch(searchInput);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchCostCenterData();
  }, []);

  const fetchCostCenterData = async () => {
    try {
      const response = await httpCall({
        method: 'GET',
        url: '/api/costcenter/get',
      });
      setLoading(false);
      if (response && response.data) {
        setCostCenterData(Array.isArray(response.data) ? response.data : []);
      } else {
        setCostCenterData([]);
        console.error('Error retrieving cost center data: Unexpected response format', response);
      }
    } catch (error) {
      setLoading(false);
      setCostCenterData([]);
      console.error('Error fetching cost center data:', error);
    }
  };

  const openNotification = (message, description, type = 'success') => {
    api.open({
      message,
      duration: 2,
      description,
      icon: <CheckCircleFilled style={{ color: type === 'success' ? '#808000' : '#FF6868' }} />,
    });
  };

  const handleEdit = (id) => {
    console.log(`Navigating to edit form with ID: ${id}`);
    navigate('/slj/cost-center/form', { state: { id } });
  };

  const handleDelete = async (id) => {
	console.log(`Attempting to delete cost center with ID: ${id}`);
	try {
	  const response = await httpCall({
		method: 'DELETE',
		url: `/api/costcenter/delete/${id}`,
	  });
	  console.log('Delete response:', response);
	  if (response && response.status === 200) {
		openNotification('Delete Success!', 'Cost Center Deleted Successfully, Refreshing Grid.');
		fetchCostCenterData(); // Refresh data after deletion
	  } else {
		openNotification('Delete Failed', response.data.error || 'Failed to delete the cost center.', 'error');
	  }
	} catch (error) {
	  console.error('Error deleting cost center:', error);
	  openNotification('Delete Failed', error.response?.data?.error || 'An error occurred while deleting the cost center.', 'error');
	}
  };
  

  const columnsNew = [
    {
      title: 'Name',
      dataIndex: 'cost_center_name',
      key: 'cost_center_name',
      ...tableColumnSearch('cost_center_name'),
      align: 'center',
      width: 300,
      sorter: (a, b) => a.cost_center_name.localeCompare(b.cost_center_name),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      width: 150,
      filters: [
        { text: 'Active', value: true },
        { text: 'InActive', value: false },
      ],
      onFilter: (value, record) => record.active === value,
      filterIcon: (filtered) => (
        <FilterFilled style={{ color: filtered ? '#1677ff' : undefined, fontSize: '16px' }} />
      ),
      render: (_, record) => (
        <Space size="middle">
          {record.active ? <Tag color="success">Active</Tag> : <Tag color="red">InActive</Tag>}
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <EyeOutlined style={{ cursor: 'pointer', fontSize: '20px', color: '#08c' }} />
          <EditOutlined
            style={{ cursor: 'pointer', fontSize: '20px', color: '#08c' }}
            onClick={() => handleEdit(record.cost_center_id)}
          />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => handleDelete(record.cost_center_id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: '20px', color: '#FF6868' }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleTotal = (total, range) => (
    <div style={{ fontFamily: 'Poppins', fontSize: '15px' }}>
      <strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of{' '}
      <strong>{total.toLocaleString()}</strong> records
    </div>
  );

  return (
    <>
      <PageTitle
        imagesrc={CostCenterImage}
        pageTitle="Cost Center List"
        buttonList={[
          { buttonName: 'Create Cost Center', className: 'btn-create', url: '/slj/cost-center/form' },
        ]}
      />
      {contextHolder}
      <div className="vendorListArea">
        <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
          <Table
            dataSource={costCenterData}
            columns={columnsNew}
            rowKey="cost_center_id"
            pagination={{
              showSizeChanger: true,
              position: ['bottomCenter'],
              pageSizeOptions: ['10', '20', '30'],
              showTotal: handleTotal,
            }}
            scroll={{ y: 570 }}
            style={{ fontFamily: 'Poppins' }}
          />
        </Spin>
      </div>
    </>
  );
};

export default CostCenterList;
