import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Skeleton, Space, Spin, Table, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import CostCenterImage from '../../assets/images/vendor.svg';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { CheckCircleFilled, DeleteOutlined, EditOutlined, EyeOutlined, FilterFilled } from '@ant-design/icons';

const VendorList = () => {
	const navigate = useNavigate();
	const searchInput = useRef(null);
	const [vendorList, setVendorList] = useState([]);
	const tableColumnSearch = columnSearch(searchInput);
	const [api, contextHolder] = notification.useNotification();
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		setLoading(true);
		fetchVendorList();
	}, []);

	const fetchVendorList = () => {
		httpCall({
			method: 'GET',
			url: '/api/vendor/get',
		})
			.then((response) => {
				setLoading(false);
				if (response) {
					setVendorList(response?.data);
				} else {
					console.error(
						'Error retrieving cost center data:',
						response.message
					);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.error('Error retrieving cost center data:', error);
			});
	};

	const openNotification = () => {
		api.open({
		  message: 'Delete Success !',
		  duration: 2,
		  description:
			'Vendor Deleted Successfully, Refreshing Grid. ',
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

	const handleEdit = (id) => {
		navigate('/slj/vendor/form', { state: { id } })
	};

	const handleDelete = (id) => {
		httpCall({
			method: 'DELETE',
			url: '/api/vendor/delete/'+ id,
		}).then(reponse => {
			openNotification();
			fetchVendorList();
		}).catch(error => {
			console.log('Error>', error)
		})
	};
  
  	const columnsNew = [
		{
			title: 'Company Name',
			dataIndex: 'company_name',
			key: 'company_name',
			...tableColumnSearch('company_name'),
			align: 'center',
			width: 300,
			sorter: (a, b) => a.company_name.localeCompare(b.company_name),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
		},
		{
			title: 'Mobile Number',
			dataIndex: 'mobile_number',
			key: 'mobile_number',
			align: 'center',
			width: 200
		},
		{
			title: 'Address',
			dataIndex: 'address',
			key: 'address',
			align: 'center',
			width: 200
		},
		{
			title: 'City',
			dataIndex: 'city',
			key: 'phone',
			align: 'center',
			width: 150,
			sorter: (a, b) => a.city.localeCompare(b.city),
			defaultSortOrder: 'ascend', // Default sorting in ascending order
			
		},
		{
			title: 'Website',
			dataIndex: 'website',
			key: 'email',
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
				vendorList.length >= 1 ? (
						<Space size="middle">
							<EyeOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
							<EditOutlined style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}} onClick={() => handleEdit(record.id)} />
							<Popconfirm title="Are you sure you want to delete?" onConfirm={() => handleDelete(record.id)}>
								<DeleteOutlined style={{fontSize: '20px', color: '#FF6868'}}/>
							</Popconfirm>
						</Space>
				) : null			
		  },
	];

	return (
		<>
			<PageTitle
				imagesrc={CostCenterImage}
				pageTitle="Vendor List"
				buttonList={[{
					buttonName: 'Create Vendor',
					className: 'btn-create',
					url: '/slj/vendor/form'
				}]}
			/>
			{contextHolder}
			{<div className='vendorListArea'>
			 <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large"><Table 
				dataSource={vendorList}
				columns={columnsNew}
				rowKey="id"
				pagination={{showSizeChanger: true, position: ['bottomCenter'], pageSizeOptions: ['10', '20', '30'], showTotal: handleTotal}}
				style={{fontFamily: 'Poppins'}}/> </Spin>
			</div>}
		</>
	);
};

const handleTotal = (total, range) => (
	<div style={{fontFamily: 'Poppins', fontSize: '15px'}}><strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records</div>
);

export default VendorList;
