import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Input, Row, notification, Select } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import CustomerImage from '../../assets/images/customer.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import customerFormFields from './customerFormFields';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';

const { Option } = Select;

const CustomerForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [api, contextHolder] = notification.useNotification();

    const openErrorNotification = (description) => {
        api.error({
            message: 'Error!',
            duration: 4,
            description: description,
        });
    };

    const validatePhoneNumber = (value) => {
        if (value && !/^\d*$/.test(value)) {
            return 'Phone number must be numeric';
        }
        if (value && value.length < 10) {
            return 'Phone number must be exactly 10 digits';
        }
        if (value && value.length > 10) {
            return 'Phone number cannot exceed 10 digits';
        }
        return '';
    };

    const validatePanCard = (value) => {
        if (!value) return '';
        // Format: AAAA0000A (4 letters + 4 numbers + 1 letter)
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (!panRegex.test(value)) {
            return 'PAN Card must be in format: AAAA0000A (4 letters + 4 numbers + 1 letter)';
        }
        return '';
    };

    const formik = useFormik({
        initialValues: {
            company_name: '',
            mailing_name: '',
            address: '',
            country: '',
            state: '',
            city: '',
            zip_code: '',
            website: '',
            pan_card_no: '',
            gst_register_type: '',
            gst_no: '',
            email: '',
            mobile_number: '',
            phone_number: '',
            price_type: '',
            credit_amount_limit: '',
            credit_days: '',
            customer_type: '',
            parent_company: '',
            assigned_to: '',
            contact_name: '',
            designation: '',
            department: '',
            contact_mobile_number: '',
            contact_email: '',
            experience: '',
            education_details: '',
            account_holder_name: '',
            account_no: '',
            confirm_account_no: '',
            ifsc_code: '',
            bank_name: '',
            branch: '',
            consignor_company_name: '',
            delivery_gst_no: '',
            delivery_gst_register_type: '',
            delivery_address: '',
            delivery_country: '',
            delivery_state: '',
            delivery_city: '',
            delivery_zip_code: '',
            delivery_contact_name: '',
            contact_designation: '',
            contact_department: '',
            delivery_contact_mobile_no: '',
            delivery_contact_email: '',
            pending_amount:0,
        },
        validate: (values) => {
            const errors = {};
            
            // Company name validation
            if (!values.company_name) {
                errors.company_name = 'Company name is required';
            }
            
            // Mobile number validation
            if (!values.mobile_number) {
                errors.mobile_number = 'Mobile number is required';
            } else if (!/^\d{10}$/.test(values.mobile_number)) {
                errors.mobile_number = 'Mobile number must be exactly 10 digits';
            }

            // PAN Card validation
            const panError = validatePanCard(values.pan_card_no);
            if (panError) errors.pan_card_no = panError;

            // Email validations
            if (values.email) {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = 'Invalid email address';
                }
            }

            if (values.contact_email) {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.contact_email)) {
                    errors.contact_email = 'Invalid contact email address';
                }
            }

            // Bank account validation
            if (values.account_no || values.confirm_account_no) {
                if (!values.account_no) {
                    errors.account_no = 'Account number is required';
                }
                if (!values.confirm_account_no) {
                    errors.confirm_account_no = 'Confirm account number is required';
                }
                if (values.account_no !== values.confirm_account_no) {
                    errors.confirm_account_no = 'Account numbers do not match';
                }
            }

            // Phone number validations
            if (values.phone_number) {
                const phoneError = validatePhoneNumber(values.phone_number);
                if (phoneError) errors.phone_number = phoneError;
            }

            if (values.contact_mobile_number) {
                const contactMobileError = validatePhoneNumber(values.contact_mobile_number);
                if (contactMobileError) errors.contact_mobile_number = contactMobileError;
            }

            if (values.delivery_contact_mobile_no) {
                const deliveryMobileError = validatePhoneNumber(values.delivery_contact_mobile_no);
                if (deliveryMobileError) errors.delivery_contact_mobile_no = deliveryMobileError;
            }

            return errors;
        },
        onSubmit: (values, { resetForm }) => {
            if (values.mobile_number === '' || values.company_name === '') {
                openErrorNotification('Please Enter Required Fields...');
                return;
            }
            
            if (location?.state?.id) {
                updateCustomer(values, resetForm);
            } else {
                createCustomer(values, resetForm);
            }
        },
    });

    useEffect(() => {
        if (location?.state?.id) {
            httpCall({
                method: 'GET',
                url: '/api/customer/getCustomerData?id=' + location?.state?.id,
            })
                .then((response) => {
                    formik.setValues(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    openErrorNotification('Failed to fetch customer data');
                });
        }
    }, [location?.state?.id]);

    useEffect(() => {
        formik.setFieldValue('mailing_name', formik.values.company_name);
    }, [formik.values.company_name]);

    const openNotification = (description) => {
        api.open({
            message: 'Success!',
            duration: 2,
            description: description,
            icon: <CheckCircleFilled style={{ color: '#808000' }} />,
        });
    };

    const createCustomer = async (values, handleReset) => {
        try {
            const response = await httpCall({
                method: 'POST',
                url: '/api/customer/create',
                payload: values,
            });

            if (response && response.status === 201) {
                openNotification("Customer Created Successfully. Go to the list view or create another one.");
                handleReset();
            } else if (response && response.status === 400) {
                openErrorNotification(response.data.error || "Mobile number already exists.");
            } else {
                openErrorNotification("Failed to create customer. Please try again.");
            }
        } catch (error) {
            console.error(error);
            api.error({
                message: "Duplicate Entry Error",
                description: "A customer with this mobile number already exists.",
                duration: 4,
            });
        }
    };

    const updateCustomer = async (values, handleReset) => {
        try {
            const response = await httpCall({
                method: 'PUT',
                url: '/api/customer/edit',
                payload: { ...values, customerId: location?.state?.id },
            });

            if (response && response.status === 200) {
                openNotification('Customer Details Updated Successfully.');
                setTimeout(() => navigate('/slj/customer'), 2500);
                handleReset();
            } else {
                throw new Error(response.data.error || 'Failed to update customer.');
            }
        } catch (error) {
            console.error(error);
            openErrorNotification(error.message || 'Failed to update customer.');
        }
    };

    const handleNumericInput = (event) => {
        const { value, id } = event.target;
        if (/^\d*$/.test(value)) {
            formik.setFieldValue(id, value);
        }
    };

    // Add handler for PAN Card input to force uppercase
    const handlePanCardInput = (event) => {
        const { value } = event.target;
        formik.setFieldValue('pan_card_no', value.toUpperCase());
    };

    const createNewFormSection = (sections) => {
        return (
            <>
                {sections.map((section, index) => (
                    <Row key={index} gutter={16} className="gutter-row">
                        {section.map((field, index) => (
                            <Col span={field.colSpan} className="gutter-box" key={index}>
                                <div>
                                    <span className="hintText">{field.label}</span>
                                    {field.isRequired && <span className="mandatory"></span>}
                                </div>

                                {field.id === 'gst_register_type' || field.id === 'delivery_gst_register_type' ? (
                                    <Select
                                        id={field.id}
                                        name={field.id}
                                        style={{ width: "100%", height: "38px" }}
                                        onChange={(value) => formik.setFieldValue(field.id, value)}
                                        value={formik.values[field.id]}
                                    >
                                        {['Regular', 'Composition', 'Unregistered', 'Consumer'].map((type) => (
                                            <Option key={type} value={type}>{type}</Option>
                                        ))}
                                    </Select>
                                ) : field.type === 'text' || field.id === 'customer_type' ? (
                                    <Input
                                        type={
                                            field.id.includes('email') ? 'email' :
                                            field.id.includes('mobile') || field.id.includes('phone') ? 'tel' :
                                            'text'
                                        }
                                        id={field.id}
                                        name={field.id}
                                        style={{ width: '100%' }}
                                        disabled={field.id === 'mailing_name' || (location?.state?.id && field.id === 'mobile_number')}
                                        onChange={
                                            field.id === 'pan_card_no' ? handlePanCardInput :
                                            field.id.includes('mobile') || field.id.includes('phone') ? handleNumericInput :
                                            formik.handleChange
                                        }
                                        value={formik.values[field.id]}
                                    />
                                ) : (
                                    <Checkbox
                                        id={field.id}
                                        style={{ width: '100%' }}
                                        onChange={formik.handleChange}
                                        checked={formik.values[field.id]}
                                    />
                                )}
                                {formik.touched[field.id] && formik.errors[field.id] && (
                                    <div style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '4px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <ExclamationCircleFilled style={{ color: 'red', marginRight: '8px' }} />
                                        {formik.errors[field.id]}
                                    </div>
                                )}
                            </Col>
                        ))}
                    </Row>
                ))}
            </>
        );
    };

    return (
        <>
            <PageTitle
                imagesrc={CustomerImage}
                pageTitle={location?.state?.id ? "Update Customer" : "Create Customer"}
                buttonList={[{
                    buttonName: 'Back',
                    className: 'btn-back',
                    url: '/slj/customer'
                }]}
            />
            {contextHolder}
            <div className="content-area">
                <div className="formAreaStyle">
                    {createNewFormSection(customerFormFields.section1)}
                    <div className="DetailsBox">
                        <div className="Tabs">
                            <div
                                className={`individualTab ${activeTab === 1 ? 'active' : ''}`}
                                onClick={() => setActiveTab(1)}
                            >
                                Contact Details
                            </div>
                            <div
                                className={`individualTab ${activeTab === 2 ? 'active' : ''}`}
                                onClick={() => setActiveTab(2)}
                            >
                                Bank Details
                            </div>
                            <div
                                className={`individualTab ${activeTab === 3 ? 'active' : ''}`}
                                onClick={() => setActiveTab(3)}
                            >
                                Delivery Address
                            </div>
                        </div>
                        {activeTab === 1 && (
                            <div className="individualTabDetails">
                                {createNewFormSection(customerFormFields.section2)}
                            </div>
                        )}
                        {activeTab === 2 && (
                            <div className="individualTabDetails">
                                {createNewFormSection(customerFormFields.section3)}
                            </div>
                        )}
                        {activeTab === 3 && (
                            <div className="individualTabDetails">
                                {createNewFormSection(customerFormFields.section4)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="formSubmitArea">
                <div className="formSubmitInnerArea">
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '170px', backgroundColor: 'grey' }}
                        onClick={formik.handleReset}
                    >
                        Reset Fields
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '170px', backgroundColor: '#1f43e5' }}
                        onClick={formik.handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CustomerForm;