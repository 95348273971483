import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Row, Spin, Table, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/sales.png';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { useFormik } from 'formik';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DayBookReport = () => {
    const navigate = useNavigate();
    const [reportData, setReportData] = useState([]);
    const [saleReportData, setSaleReportData] = useState([]);
    const [saleReceiptReportData, setSaleReceiptReportData] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            from_date: moment().format('YYYY-MM-DD'),
            to_date: moment().format('YYYY-MM-DD')
        },
        onSubmit: (values, { resetForm }) => { }
    });

    useEffect(() => {
        fetchSaleList(formik.values.from_date, formik.values.to_date);
        fetchSaleReceiptList(formik.values.from_date, formik.values.to_date);
        fetchMaterialReturnList(formik.values.from_date, formik.values.to_date);
    }, []);

    const fetchMaterialReturnList = (from, to) => {
        httpCall({
            method: 'GET',
            url: `/api/sales/material-return/report?from=${from}&to=${to}`
        })
            .then((response) => {
                setLoading(false);
                setReportData(response?.data || []);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error retrieving sales order data:', error);
            });
    };

    const fetchSaleList = (from, to) => {
        httpCall({
            method: 'GET',
            url: `/api/sales/sale/report?from=${from}&to=${to}`
        })
            .then((response) => {
                setLoading(false);
                setSaleReportData(response?.data || []);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error retrieving sales order data:', error);
            });
    };

    const fetchSaleReceiptList = (from, to) => {
        httpCall({
            method: 'GET',
            url: `/api/sales/sale-receipt/report?from=${from}&to=${to}`
        })
            .then((response) => {
                setLoading(false);
                setSaleReceiptReportData(response?.data || []);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error retrieving sales order data:', error);
            });
    };

    // Safe comparison function for sorting
    const safeCompare = (a, b) => {
        if (a === undefined && b === undefined) return 0;
        if (a === undefined) return -1;
        if (b === undefined) return 1;
        return a.toString().localeCompare(b.toString());
    };

    const saleReportColumns = [
        {
            title: 'Item Name',
            dataIndex: 'itemname',
            key: 'itemname',
            align: 'center',
            width: 150,
            sorter: (a, b) => safeCompare(a.itemname, b.itemname),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Bill Nr',
            dataIndex: 'invoice_no',
            key: 'invoice_no',
            align: 'center',
            width: 125,
            sorter: (a, b) => safeCompare(a.invoice_no, b.invoice_no),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const dateA = a.date ? moment(a.date) : null;
                const dateB = b.date ? moment(b.date) : null;
                if (!dateA && !dateB) return 0;
                if (!dateA) return -1;
                if (!dateB) return 1;
                return dateA - dateB;
            },
            defaultSortOrder: 'ascend',
            render: (_, record) => (record.date ? moment(record.date).format('DD-MM-YYYY') : '')
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: 75
        }
    ];

    const saleReceiptReportColumns = [
        {
            title: 'Receipt Nr',
            dataIndex: 'invoice_receipt_no',
            key: 'invoice_receipt_no',
            align: 'center',
            width: 125,
            sorter: (a, b) => safeCompare(a.invoice_receipt_no, b.invoice_receipt_no),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Bill Nr',
            dataIndex: 'invoice_no',
            key: 'invoice_no',
            align: 'center',
            width: 125,
            sorter: (a, b) => safeCompare(a.invoice_no, b.invoice_no),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Receipt Date',
            dataIndex: 'invoice_receipt_date',
            key: 'invoice_receipt_date',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const dateA = a.date ? moment(a.date) : null;
                const dateB = b.date ? moment(b.date) : null;
                if (!dateA && !dateB) return 0;
                if (!dateA) return -1;
                if (!dateB) return 1;
                return dateA - dateB;
            },
            defaultSortOrder: 'ascend',
            render: (_, record) => (record.date ? moment(record.date).format('DD-MM-YYYY') : '')
        },
        {
            title: 'Cash Payment',
            dataIndex: 'paid_amount_1',
            key: 'paid_amount_1',
            align: 'center',
            width: 75,
            render: (_, record) => {
                const isPayment1Cash = record.payment_mode_1 && record.payment_mode_1.toLowerCase() === 'cash';
                const isPayment2Cash = record.payment_mode_2 && record.payment_mode_2.toLowerCase() === 'cash';
                return isPayment1Cash ? record.paid_amount_1 : (isPayment2Cash ? record.paid_amount_2 : '');
            }
        },
        {
            title: 'Bank/Card Payment',
            dataIndex: 'paid_amount_2',
            key: 'paid_amount_2',
            align: 'center',
            width: 75,
            render: (_, record) => {
                const isPayment1Card = record.payment_mode_1 && record.payment_mode_1.toLowerCase() !== 'cash';
                const isPayment2Card = record.payment_mode_2 && record.payment_mode_2.toLowerCase() !== 'cash';
                return isPayment1Card ? record.paid_amount_1 : (isPayment2Card ? record.paid_amount_2 : '');
            }
        }
    ];

    const columnsNew = [
        {
            title: 'Item Name',
            dataIndex: 'itemname',
            key: 'itemname',
            align: 'center',
            width: 150,
            sorter: (a, b) => safeCompare(a.itemname, b.itemname),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Bill Nr',
            dataIndex: 'return_item_no',
            key: 'return_item_no',
            align: 'center',
            width: 125,
            sorter: (a, b) => safeCompare(a.return_item_no, b.return_item_no),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Date',
            dataIndex: 'return_item_date',
            key: 'return_item_date',
            align: 'center',
            width: 100,
            sorter: (a, b) => {
                const dateA = a.date ? moment(a.date) : null;
                const dateB = b.date ? moment(b.date) : null;
                if (!dateA && !dateB) return 0;
                if (!dateA) return -1;
                if (!dateB) return 1;
                return dateA - dateB;
            },
            defaultSortOrder: 'ascend',
            render: (_, record) => (record.date ? moment(record.date).format('DD-MM-YYYY') : '')
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            align: 'center',
            width: 75
        }
    ];

    const mergedColumns = React.useMemo(() => {
        return columnsNew.map((col) => ({
            ...col,
            onCell: undefined
        }));
    }, []);

    const footerLabel = (list, label, field, unit) => {
        return (
            <>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <div style={{ width: '20%', textAlign: 'center', backgroundColor: '#FFFFFF' }}>
                    <span>{label}: </span>
                    <span style={{ fontSize: '18px', fontWeight: '600' }}>
                        {list.reduce((acc, next) => (next[field] || 0) + acc, 0)} {unit}
                    </span>
                </div>
            </div>
        </>
    );
};

return (
    <>
        <PageTitle imagesrc={SalesImage} pageTitle="Day Book Report" buttonList={[]} />
        {contextHolder}
        <div className="vendorListArea">
            <Row gutter={16} className="gutter-row" style={{ marginBottom: '30px' }}>
                <Col span={12} className="gutter-box">
                    <div>
                        <span className="hintText color-black">Select Date Range</span>
                    </div>
                    <RangePicker
                        style={{ height: '38px' }}
                        onChange={(e) => {
                            if (!!e) {
                                const start = e[0].format('YYYY-MM-DD');
                                const end = e[1].format('YYYY-MM-DD');
                                fetchMaterialReturnList(start, end);
                                fetchSaleList(start, end);
                                fetchSaleReceiptList(start, end);
                            }
                        }}
                    />
                </Col>
            </Row>
            <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '50px' }}>
                    <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Gold Sale</span>
                        <Table
                            dataSource={saleReportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'gold')}
                            size="small"
                            bordered
                            columns={saleReportColumns}
                            rowKey="invoice_no"
                            scroll={{ y: '250px' }}
                            pagination={false}
                            footer={() =>
                                footerLabel(saleReportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'gold'), 'Total Weight', 'weight', 'g')
                            }
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
                        />
                    </Col>
                </Row>

                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '50px' }}>
                    <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Silver Sale</span>
                        <Table
                            dataSource={saleReportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'silver')}
                            size="small"
                            bordered
                            columns={saleReportColumns}
                            rowKey="invoice_no"
                            scroll={{ y: '250px' }}
                            pagination={false}
                            footer={() =>
                                footerLabel(saleReportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'silver'), 'Total Weight', 'weight', 'g')
                            }
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
                        />
                    </Col>
                </Row>

                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '50px' }}>
                    <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Sales Receipt</span>
                        <Table
                            dataSource={saleReceiptReportData}
                            size="small"
                            bordered
                            columns={saleReceiptReportColumns}
                            rowKey="invoice_receipt_no"
                            scroll={{ y: '250px' }}
                            pagination={false}
                            footer={() => footerLabel(saleReceiptReportData, 'Total Amount', 'paid_amount', '₹')}
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
                        />
                    </Col>
                </Row>

                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '30px' }}>
                    <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Old Gold Purchase</span>
                        <Table
                            dataSource={reportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'gold')}
                            size="small"
                            bordered
                            columns={mergedColumns}
                            rowKey="return_item_no"
                            scroll={{ y: '250px' }}
                            pagination={false}
                            footer={() =>
                                footerLabel(reportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'gold'), 'Total Weight', 'weight', 'g')
                            }
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
                        />
                    </Col>
                </Row>

                <Row gutter={16} className="gutter-row" style={{ marginTop: '5px', marginBottom: '30px' }}>
                    <Col span={24} className="gutter-box">
                        <span style={{ fontSize: '15px', fontWeight: '600' }}>Old Silver Purchase</span>
                        <Table
                            dataSource={reportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'silver')}
                            size="small"
                            bordered
                            columns={mergedColumns}
                            rowKey="return_item_no"
                            scroll={{ y: '250px' }}
                            pagination={false}
                            footer={() =>
                                footerLabel(reportData.filter((r) => r.item_type && r.item_type.toLowerCase() === 'silver'), 'Total Weight', 'weight', 'g')
                            }
                            style={{ fontFamily: 'Poppins', marginTop: '10px' }}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>
    </>
);
};

export default DayBookReport;
