import React, { useEffect, useRef, useState } from 'react';
import { Spin,Button, Card, Col, DatePicker, Divider, Input, InputNumber, Modal, Row, Select, Space, Statistic, Switch, Table, Tag, notification } from 'antd';
import { ScanOutlined ,StopOutlined,AlertOutlined, CheckCircleFilled, ClearOutlined, DeleteFilled, EditFilled, FileImageOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import SaleImage from '../../assets/images/sales.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

const {Option} = Select;

const QuotationForm = () => { 
	const location = useLocation();
	const navigate = useNavigate();
    const [costCenterList, setCostCenterList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [itemDataList, setItemDataList] = useState([]);
    const [itemDataOptions, setItemDataOptions] = useState([]);
    const [custMobileOptions, setCustMobileOptions] = useState([]);
	const [api, contextHolder] = notification.useNotification();
    const [salesItemList, setSalesItemList] = useState([]);
    const [salesReturnItemList, setSalesReturnItemList] = useState([]);
    const [plants, setPlants] = useState([]);
    const [plantOptions, setPlantOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [itemTypes, setItemTypes] = useState([]);
    const [modelType, setModelType] = useState('');
    const [isNewCustomer, setIsNewCustomer] = useState(false);  
    const [isUpdate, setIsUpdate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isScanning, setIsScanning] = useState(false);
    const [scanLoadingVisible, setScanLoadingVisible] = useState(false);

    const componentRef = useRef();
    
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
    });
    
    const showModal = (type, isEdit) => {
        setIsEdit(!!isEdit);
        setModelType(type);
    };

    const handleOk = (isSaleType) => {
        if(isSaleType) {
            addNewItem(salesItemFormik, salesItemList, setSalesItemList, false);
        } else {
            addNewItem(salesItemReturnFormik, salesReturnItemList, setSalesReturnItemList, true);
        }        
    };

    const handleCancel = () => {
        setModelType('');
        setIsNewCustomer(false);
        setIsScanning(false);
        setScanLoadingVisible(false);
    };

    const ceilToTwoDecimals = (number) => {
      return (Math.ceil(number * 100) / 100).toFixed(2);
  };

    const formik = useFormik({
		initialValues: {
            quotation_no_inc: '',
            quotation_no: '',
            customer_id: '',
            quotation_date: moment(),
            items_amount: '',
            return_items_amount: '',
            total_amount: '',
            sales_person_id: '',
            isNewCustomer: false,
            customer_mobile: '',
            customer_name: '',
            customer_address: '',
            new_customer_mobile: '',
            new_customer_name: '',
            new_customer_address: '',
            plant_code: '',
            quotationHeader: '',
            module: 'QUOTATION',
            salesPerson: {},
            plant: {},
            customer: {},
		},
		onSubmit: (values, {resetForm}) => {
            if(!hasValidated(values)) {
                return;
            }
			if(location?.state?.id) {
				updateQuotation(values, resetForm);
			} else {
				createQuotation(values, resetForm);
			}
		},
	});

    const salesItemFormik = useFormik({
		initialValues: {
            item_id: '',
            sales_item_name: '',
            item_option: {},
            pcs: '',
            weight: '',
            igst: '',
            cgst: '',
            sgst: '',
            tax_amount: '',
            amount: '',
            stone: '',
            stone_weight:'',
            gross_weight:'',
            waste: '',
            waste_unit: 'g',
            huid: '',
            mc_amount: '',
            other_details: ''
		}
	});

    const salesItemReturnFormik = useFormik({
		initialValues: {
            item_id: '',
            sales_item_name: '',
            item_option: {},
            pcs: '',
            weight: '',
            amount: '',
            stone: '',
            gross_weight:'',
            stone_weight:' ',
            waste: '',
            waste_unit: 'g',
            huid: '',
            mc_amount: '',
            other_details: ''
		}
	});

    const hasValidated = (values) => {
        if(values.customer_id === '') {
            openErrorNotification('Please Select a Customer.');
            return false;
        }
        if(values.plant_code === '') {
            openErrorNotification('Please Select a Plant.');
            return false;
        }
        if(values.sales_person_id === '') {
            openErrorNotification('Please Select Sales Person');
            return;
        }
        if(salesItemList.length === 0) {
            openErrorNotification('Please add a sales item');
            return false;
        }

        return true;
    }

    useEffect(() => {
        fetchCostCenter();
        fetchCustomerList();
        fetchItemDataList();
        fetchPlantList();
        fetchItemTyeps();
		if(location?.state?.id) {
            setTimeout(() => {
                prefillQuotationValues();
            }, 0);            
		} else {
            fetchNextBillNo();
        }
	}, []);

    useEffect(() => {        
        if(isUpdate) {
            const salesPerson = costCenterList?.find(sp => sp.value === formik.values.sales_person_id);
            formik.setFieldValue('salesPerson', salesPerson);
            
            const plant = plantOptions?.find(p => p.value === formik.values.plant_code);
            formik.setFieldValue('plant', plant);

            const customer = customerList?.find(c => c.id === formik.values.customer_id);
            formik.setFieldValue('customer', {label: customer.mobile_number, value: customer.mobile_number});
            formik.setFieldValue("customer_id", customer.id);
            formik.setFieldValue("customer_name", customer.company_name);
            formik.setFieldValue("customer_address", customer.address);

        }
	}, [isUpdate]);

    const prefillQuotationValues = () => {
        httpCall({
            method: 'GET',
            url: '/api/sales/quotation?quotationno=' + location?.state?.id,
        })
            .then((response) => {
                const quoDetails = response.data.quotationDetails;
                const items = response.data.items;
                const return_items = response.data.return_items;
                setIsUpdate(true);
                
                formik.setFieldValue('quotation_no', quoDetails.quotation_no);
                formik.setFieldValue('sales_person_id', quoDetails.sales_person_id);
                formik.setFieldValue('plant_code',  quoDetails.plant_code);
                formik.setFieldValue('customer_id',  quoDetails.customer_id);
                formik.setFieldValue('items_amount', Number(quoDetails.items_amount));
                formik.setFieldValue('return_items_amount', Number(quoDetails.return_items_amount));
                formik.setFieldValue('total_amount', Number(quoDetails.total_amount));
                formik.setFieldValue('quotation_date', moment(quoDetails.quotation_date, 'YYYY-MM-DD'));

                for (let index = 0; index < items.length; index++) {
                    const item = items[index];
                    const salesItem = {
                        isSalesReturn: false,
                        sub_module: 'ITEM',
                        item_id: item.item_id,
                        sales_item_name: item.item_name,
                        item_option: {label: item.item_name, value: item.item_name},
                        pcs: item.pcs || 0,
                        weight: item.weight,
                        current_rate: item.current_rate,
                        amount: item.amount,
                        stone: item.stone,        
                        gross_weight:item.gross_weight,
                        stone_weight:item.stone_weight,
                        waste: item.waste,
                        waste_unit: item.waste_unit,
                        mc_amount: item.mc_amount,
                        huid: item.huid,
                        igst: item.igst,
                        cgst: item.cgst,
                        sgst: item.sgst,
                        tax_amount: item.tax_amount,
                        other_details: item.other_details
                    }
                    setSalesItemList([...salesItemList, salesItem]);
                }
                for (let index = 0; index < return_items.length; index++) {
                    const item = return_items[index];
                    const salesReturnItem = {
                        isSalesReturn: true,
                        sub_module: 'RETURN_ITEM',
                        item_id: item.item_id,
                        sales_item_name: item.item_name,
                        item_option: {label: item.item_name, value: item.item_name},
                        pcs: item.pcs || 0,
                        weight: item.weight,
                        current_rate: item.current_rate,
                        amount: item.amount,
                        stone: item.stone,
                        stone_weight:item.stone_weight,
                        gross_weight:item.gross_weight,
                        waste: item.waste,
                        waste_unit: item.waste_unit,
                        mc_amount: item.mc_amount,
                        huid: item.huid,
                        igst: item.igst,
                        cgst: item.cgst,
                        sgst: item.sgst,
                        tax_amount: item.tax_amount,
                        other_details: item.other_details
                    }
                    setSalesReturnItemList([...salesReturnItemList, salesReturnItem]);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const fetchItemTyeps = () => {
		setLoading(true);
		httpCall({
			method: 'GET',
			url: '/api/itemtype/list',
		})
			.then((response) => {
				if (response) {
                    setItemTypes(response?.data);
				} else {
					console.error(
						'Error retrieving item type list',
						response.message
					);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error retrieving item type list', error);
				setLoading(false);
			});
	};

    useEffect(() => {
        calculateTotal();
	}, [salesItemList, salesReturnItemList]);

    useEffect(() => {
        if(!!salesItemFormik.values.weight && !!salesItemFormik.values.current_rate) {
            const current_rate = parseFloat(salesItemFormik.values.current_rate || 0);
            const weight = parseFloat(salesItemFormik.values.weight || 0);
            let waste = parseFloat(salesItemFormik.values.waste || 0);
            const waste_unit = salesItemFormik.values.waste_unit;
            const stone = parseFloat(salesItemFormik.values.stone || 0);
            const gross_weight = parseFloat(salesItemFormik.values.gross_weight || 0);
            const stone_weight = parseFloat(salesItemFormik.values.stone_weight || 0);
            const mc_amount = parseFloat(salesItemFormik.values.mc_amount || 0);
            waste = waste_unit === '%' ? (weight * waste) / 100 : waste;

            let amt = ((weight + waste) * current_rate) + stone + mc_amount;

            const igst = parseFloat(salesItemFormik.values.cgst || 0) + parseFloat(salesItemFormik.values.sgst || 0);
            const igstAmt = (amt * igst)/100;
            salesItemFormik.setFieldValue('tax_amount', igstAmt.toFixed(3));
            amt = amt + igstAmt;
            salesItemFormik.setFieldValue('amount', amt.toFixed(3));
        }
	}, [salesItemFormik.values.weight,
        salesItemFormik.values.waste, 
        salesItemFormik.values.waste_unit,
        salesItemFormik.values.stone, 
        salesItemFormik.values.gross_weight, 
        salesItemFormik.values.stone_weight, 
        salesItemFormik.values.mc_amount
    ]);

    useEffect(() => {
        if(!!salesItemReturnFormik.values.weight && !!salesItemReturnFormik.values.current_rate) {
            const current_rate = parseFloat(salesItemReturnFormik.values.current_rate || 0);
            const weight = parseFloat(salesItemReturnFormik.values.weight || 0);
            let waste = parseFloat(salesItemReturnFormik.values.waste || 0);
            const waste_unit = salesItemReturnFormik.values.waste_unit;
            const stone = parseFloat(salesItemReturnFormik.values.stone || 0);
            const gross_weight = parseFloat(salesItemReturnFormik.values.gross_weight || 0);
            const stone_weight = parseFloat(salesItemReturnFormik.values.stone_weight || 0);
            const mc_amount = parseFloat(salesItemReturnFormik.values.mc_amount || 0);
            waste = waste_unit === '%' ? (weight * waste) / 100 : waste;

            const amt = ((weight - waste) * current_rate) - stone - mc_amount;
            salesItemReturnFormik.setFieldValue('amount', amt.toFixed(3));
        }
	}, [salesItemReturnFormik.values.weight,
        salesItemReturnFormik.values.current_rate,
        salesItemReturnFormik.values.waste, 
        salesItemReturnFormik.values.waste_unit,
        salesItemReturnFormik.values.stone, 
        salesItemReturnFormik.values.stone_weight, 
        salesItemReturnFormik.values.gross_weight, 
        salesItemReturnFormik.values.mc_amount
    ]);

// For salesItemFormik
useEffect(() => {
    const gross = parseFloat(salesItemFormik.values.gross_weight || 0);
    const stone = parseFloat(salesItemFormik.values.stone_weight || 0);

    // Only calculate net weight if both gross or stone weight have valid values
    if (!isNaN(gross) && !isNaN(stone)) {
        const netWeight = Math.max(0, gross - stone);
        // Set the net weight dynamically as the result
        salesItemFormik.setFieldValue('weight', netWeight > 0 ? netWeight.toFixed(3) : '');
    } else {
        // If both values are empty or invalid, keep the net weight as empty
        salesItemFormik.setFieldValue('weight', '');
    }
}, [salesItemFormik.values.gross_weight, salesItemFormik.values.stone_weight]);

// For salesItemReturnFormik
useEffect(() => {
    const gross = parseFloat(salesItemReturnFormik.values.gross_weight || 0);
    const stone = parseFloat(salesItemReturnFormik.values.stone_weight || 0);

    // Only calculate net weight if both gross or stone weight have valid values
    if (!isNaN(gross) && !isNaN(stone)) {
        const netWeight = Math.max(0, gross - stone);
        // Set the net weight dynamically as the result
        salesItemReturnFormik.setFieldValue('weight', netWeight > 0 ? netWeight.toFixed(3) : '');
    } else {
        // If both values are empty or invalid, keep the net weight as empty
        salesItemReturnFormik.setFieldValue('weight', '');
    }
}, [salesItemReturnFormik.values.gross_weight, salesItemReturnFormik.values.stone_weight]);

	const fetchPlantList = () => {
		httpCall({
			method: 'GET',
			url: '/api/plant/list',
		})
			.then((response) => {
                setPlants(response?.data);
                setPlantOptions(response?.data.map(p => ({label: p.plant_name, value: p.plant_code})))
			})
			.catch((error) => {
			});
	};

    const fetchCostCenter = () => {
		httpCall({
			method: 'GET',
			url: '/api/costcenter/get',
		})
			.then((response) => {
				if (response) {
                    setCostCenterList(response?.data.map(c => ({label: c.cost_center_name, value: c.cost_center_id})));
				} else {
					console.error(
						'Error retrieving categories data:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving categories data:', error);
			});
	};

    const fetchCustomerList = () => {
		httpCall({
			method: 'GET',
			url: '/api/customer/get',
		})
			.then((response) => {
				if (response) {
                    setCustomerList(response?.data);
                    setCustMobileOptions(response?.data.map(c => ({label: c.mobile_number, value: c.mobile_number})))
				} else {
					console.error(
						'Error retrieving customer list:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving customer list:', error);
			});
	};

  const fetchItemDataList = () => {
    setLoading(true);
    httpCall({
        method: 'GET',
        url: '/api/item/sale/list',
    })
    .then((response) => {
        if (response) {
            setItemDataList(response?.data);
            setItemDataOptions(response?.data.map(i => ({
                label: i.itemname,
                value: i.itemname
            })));
        } else {
            console.error('Error retrieving item data list:', response.message);
        }
        setLoading(false);
    })
    .catch((error) => {
        console.error('Error retrieving item data list:', error);
        setLoading(false);
    });
};

    const fetchNextBillNo = () => {
		httpCall({
			method: 'GET',
			url: '/api/sales/getQuotationNr',
		})
			.then((response) => {
				if (response) {
                    formik.setFieldValue('quotation_no_inc', response.data);
                    formik.setFieldValue('quotation_no', `QT${response.data}/${getCurrentDate()}`);
				} else {
					console.error(
						'Error retrieving next billno:',
						response.message
					);
				}
			})
			.catch((error) => {
				console.error('Error retrieving next billno:', error);
			});
	};

    const getCurrentDate = () => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}${month<10?`0${month}`:`${month}`}${year}`;
      }
      const getCurrentDate1 = () => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${date}/${month<10?`0${month}`:`${month}`}/${year}`;
      }

	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};

    const createQuotation = (values, handleReset) => {
        const quotation_date = moment(values.quotation_date).format('YYYY-MM-DD');
        ['item_option', 'customer_address', 'customer_mobile', 'customer_name', 'isNewCustomer', 
        'new_customer_address', 'new_customer_mobile', 'new_customer_name', 'quotationHeader'].forEach((prop) => delete values[prop]);
		httpCall({
			method: 'POST',
			url: '/api/sales/quotation',
			payload: {...values, quotation_date, items: [...salesItemList, ...salesReturnItemList]}
		})
			.then((response) => {
				if (response) {
					openNotification('Quotation Created Successfully.');		
					handleReset();
                    setTimeout(() => { navigate('/slj/quotation')})
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const updateQuotation = (values, handleReset) => {
        ['item_option', 'customer_address', 'customer_mobile', 'customer_name', 'isNewCustomer', 
        'new_customer_address', 'new_customer_mobile', 'new_customer_name', 'quotationHeader'].forEach((prop) => delete values[prop]);        
		httpCall({
			method: 'PUT',
			url: '/api/sales/quotation',
			payload: {...values, items: [...salesItemList, ...salesReturnItemList]}
		})
			.then((response) => {
				if (response) {
					openNotification('Quotation Details Updated Successfully, Goto list view. ');		
					setTimeout(() => navigate('/slj/quotation'), 2500)
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

    const createCustomer = () => {
		httpCall({
			method: 'POST',
			url: '/api/customer/create',
			payload: {company_name: formik.values.new_customer_name, address: formik.values.new_customer_address, mobile_number: formik.values.new_customer_mobile},
		})
			.then((response) => {
                openNotification('Customer created successfully.');
                setIsNewCustomer(false);
                setModelType('');
                fetchCustomerList();
			})
			.catch((error) => {
                if(error.response.status === 403) {
                    console.error(error.response.data.message);
                } else {
                    console.error(error);
                }
			});
    }

    const calculateTotal = () => {
        const salesBillAmount = salesItemList.reduce((acc, next) => acc + parseFloat(next.amount), 0);
        const oldGoldAmount = salesReturnItemList.reduce((acc, next) => acc + parseFloat(next.amount), 0);

        formik.setFieldValue('items_amount', salesBillAmount); 
        formik.setFieldValue('return_items_amount', oldGoldAmount);        
        formik.setFieldValue('total_amount', (salesBillAmount - oldGoldAmount));
    }
    

    const salesItemColumns = [
        {
			title: 'Item Name',
			dataIndex: 'sales_item_name',
			key: 'sales_item_name',
			align: 'center',
			width: 250
		},
        {
			title: 'PCS',
			dataIndex: 'pcs',
			key: 'pcs',		
			align: 'center',
			width: 70
		},
        {
			title: 'Weight',
			dataIndex: 'weight',
			key: 'weight',		
			align: 'center',
			width: 70
		},
        {
			title: 'Current Rate',
			dataIndex: 'current_rate',
			key: 'current_rate',		
			align: 'center',
			width: 100
		},
        {
			title: 'MC Amount',
			dataIndex: 'mc_amount',
			key: 'mc_amount',		
			align: 'center',
			width: 120
		},
        {
			title: 'HUID',
			dataIndex: 'huid',
			key: 'huid',		
			align: 'center',
			width: 100
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',		
			align: 'center',
			width: 200
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			width: 150,
			render: (_, record) => 
                (
                    <Space size="middle">
                        <EditFilled
                            onClick={() => {
                                if(record.isSalesReturn) {
                                    const salesReturn = salesReturnItemList.find(item => item.item_id === record.item_id);                                    
                                    salesItemReturnFormik.setValues(salesReturn);
                                    showModal('RETURN', true);
                                } else {
                                    const salesItem = salesItemList.find(item => item.item_id === record.item_id);
                                    salesItemFormik.setValues(salesItem);
                                    showModal('SALE', true);
                                }                                
                            }}
                            style={{cursor: 'pointer', fontSize: '20px', color: '#08c'}}/>
                        <DeleteFilled 
                            onClick={() => {
                                if(record.isSalesReturn) {
                                    const idx = salesReturnItemList.findIndex(item => item.item_id === record.item_id);
                                    salesReturnItemList.splice(idx, 1)
                                    setSalesReturnItemList([...salesReturnItemList]);
                                } else {
                                    const idx = salesItemList.findIndex(item => item.item_id === record.item_id);
                                    salesItemList.splice(idx, 1);
                                    setSalesItemList([...salesItemList]);
                                }
                            }}
                            style={{cursor: 'pointer', fontSize: '20px', color: '#B31312'}}/>
                    </Space>
                )
		  },
	];

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const salesItemTable = (datasoure) => {
        return datasoure.length > 0 ? (
            <>
                <Row gutter={16} className="gutter-row sales-list">
                    <Col span={24} className="gutter-box">
                    <Table
						dataSource={datasoure}
						columns={salesItemColumns}
                        pagination={{position: ['bottomCenter'], pageSize: 2}}
						rowKey="sales_item_name"
                        size='small'
                        bordered="true"						
						scroll={{ y: 100 }}
						style={{fontFamily: 'Poppins'}}/>
                    </Col>
                </Row>
            </>
        ) : null
    }

    const salesOrderForm = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row">                    
                    <Col span={4} offset={20} className="gutter-box" style={{textAlign: 'right'}}>
                        <div>
                            <span className="hintText">Quotation Nr</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="quotation_no"
                            disabled={true}
                            variant="borderless"
                            style={{ width: '100%', color: '#000000', padding: '5px 2px', fontSize: '14px', fontWeight: '700',  textAlign: 'right'}}
                            value={formik.values['quotation_no']}
                        />                                
                    </Col>
                </Row>
                <Divider orientation='left' orientationMargin='0'>Quotation Details</Divider>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box" style={{display: 'flex', alignItems: 'center'}}>
                        <Switch disabled={isUpdate} checked={isNewCustomer} checkedChildren='New Customer' unCheckedChildren='Existing Customer' 
                            onChange={(checked, e) => {
                                showModal('NEW_CUSTOMER');
                                setIsNewCustomer(checked);
                            }}
                        />
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Mobile Number</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Select
                            id="no"
                            showSearch
                            placeholder="Select a Number"
                            filterOption={filterOption}
                            style={{ width: '100%', height: '38px' }}
                            options={custMobileOptions}
                            value={formik.values.customer}
                            onChange={(e)=> {
                                const cust = customerList.find(c => c.mobile_number === e);
                                formik.setFieldValue("customer", e);
                                formik.setFieldValue("customer_id", cust.id);
                                formik.setFieldValue("customer_name", cust.company_name);
                                formik.setFieldValue("customer_address", cust.address);
                            }}
                        />  
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Name</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="customer_name"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            value={formik.values.customer_name}
                        />     
                    </Col>
                    <Col span={8} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Address</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="customer_address"
                            disabled={true}
                            style={{ width: '100%', color: '#000000'}}
                            value={formik.values.customer_address}
                        />                                
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={4} className="gutter-box">
                        <div>
                            <span className="hintText">Quotation Date</span>                            
                        </div> 
                        <DatePicker
                            style={{ width: '100%', height: '38px' }}                            
                            disabled={true}
                            name='quotation_date'
                            format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
                            }}
                            value={formik.values.quotation_date}
                        />                             
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Plant</span>
                            <span className="mandatory"></span>                         
                        </div> 
                        <Select
                            id="plant_code"
                            placeholder="Select a Plant"
                            style={{ width: '100%', height: '38px' }}
                            options={plantOptions}
                            value={formik.values.plant}
                            onChange={(e)=> {
                                formik.setFieldValue("plant_code", e);
                                formik.setFieldValue("plant", e);
                                const plant = plants.find(p => p.plant_code === e);
                                const qHeader = `${plant.plant_name}, ${plant.address}, ${plant.city}-${plant.zipcode}, ${plant.phone_no}`;
                                formik.setFieldValue("quotationHeader", qHeader);
                            }}
                        />   
                    </Col>
                    <Col span={6} className="gutter-box">
                        <div>
                            <span className="hintText">Sales Person Name</span> 
                            <span className="mandatory"></span>                       
                        </div> 
                        <Select
                            id="sales_person_id"
                            placeholder="Select a Name"
                            style={{ width: '100%', height: '38px' }}
                            options={costCenterList}
                            value={formik.values.salesPerson}
                            onChange={(e)=> {
                                formik.setFieldValue("salesPerson", e);
                                formik.setFieldValue("sales_person_id", e);
                            }}
                        />   
                    </Col>
                </Row>
            </>
        )
    }

    const newCustomer = () => {
        return (
            <>
               <Tag icon={<AlertOutlined />} color="magenta">Basic details are provided here, you can update full details in master section. </Tag>
               <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Mobile Number</span>
                            <span className="mandatory"></span>
                        </div>                                
                        <Input
                            type="text"
                            id="new_customer_mobile"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_mobile}
                        />   
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Name</span>
                            <span className="mandatory"></span>                        
                        </div> 
                        <Input
                            type="text"
                            id="new_customer_name"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_name}
                        />     
                    </Col>
                </Row>
                <Row gutter={16} className="gutter-row">
                    <Col span={24} className="gutter-box">
                        <div>
                            <span className="hintText">Customer Address</span>                            
                        </div> 
                        <Input
                            type="text"
                            id="new_customer_address"
                            style={{ width: '100%', color: '#000000'}}
                            onChange={formik.handleChange}
                            value={formik.values.new_customer_address}
                        />                                
                    </Col>
                </Row>                
            </>
        )
    }

    const updateItemFields = (item_name, form, isReturn) => {
        form.setFieldValue("sales_item_name", item_name);
        const item = itemDataList.find(i => i.itemname === item_name);
        form.setFieldValue("item_id", item.itemid);
        form.setFieldValue("product", item.category_name);
        form.setFieldValue("sub_product", item.sub_category_name);
        form.setFieldValue("unit", item.unit);
        form.setFieldValue("stone", item.stone);
        form.setFieldValue("gross_weight", item.gross_weight);
        form.setFieldValue("stone_weight", item.stone_weight);
        form.setFieldValue("waste", item.waste);
        form.setFieldValue("waste_unit", item.waste_unit);
        if(!isReturn) {
            form.setFieldValue("current_rate", item.current_rate);
        }        
        form.setFieldValue("igst", item.igst);
        form.setFieldValue("cgst", item.cgst);
        form.setFieldValue("sgst", item.sgst);
    }

    const addNewItem = (form, itemList, setItemList, isReturn) => {
        if(!isEdit && itemList.some(i => i.item_id === form.values.item_id)) {
            openErrorNotification('Same Item/Return alreay added, pls update if you need.');
            return;
        }
        const salesItem = {
            isSalesReturn: isReturn,
            sub_module: isReturn ? 'RETURN_ITEM' : 'ITEM',
            item_id: form.values.item_id,
            sales_item_name: form.values.sales_item_name,
            item_option: form.values.item_option,
            pcs: form.values.pcs || 0,
            weight: form.values.weight,
            current_rate: form.values.current_rate,
            amount: form.values.amount,
            stone: form.values.stone,
            stone_weight: form.values.stone_weight,
            gross_weight: form.values.gross_weight,
            waste: form.values.waste,
            waste_unit: form.values.waste_unit,
            mc_amount: form.values.mc_amount,
            huid: form.values.huid,
            igst: form.values.igst,
            cgst: form.values.cgst,
            sgst: form.values.sgst,
            tax_amount: form.values.tax_amount,
            other_details: form.values.other_details
        }

        if(salesItem.sales_item_name && salesItem.weight !== '' && (!isReturn || salesItem.current_rate)) {
            if(isEdit) {
                itemList = itemList.filter(i => i.item_id !== salesItem.item_id);
            }
            setItemList([...itemList, salesItem]);
            form.resetForm();
            form.setFieldValue('sales_item_name', '');
            form.setFieldValue('item_option', {});
            setModelType('');
        } else {
            openErrorNotification('Please Enter Required Item Fields.');
        }
    }

     const [barcode, setBarcode] = useState("No Barcode Scanned");
     useEffect(() => {
       if (barcode !== "No Barcode Scanned" && isScanning) {
         try {
           const scannedData = JSON.parse(barcode);

           // Common function to populate form fields
           const populateFormFields = (formik) => {
             formik.setFieldValue("item_id", scannedData.item_id);
             formik.setFieldValue("sales_item_name", scannedData.item_name);
             formik.setFieldValue("gross_weight", scannedData.gross_weight);
             formik.setFieldValue("weight", scannedData.net_weight);
             formik.setFieldValue("stone_weight", scannedData.stone_weight);
             formik.setFieldValue("stone", scannedData.stone_amount);
             formik.setFieldValue("waste", scannedData.waste);
             formik.setFieldValue("waste_unit", scannedData.waste_unit);
             formik.setFieldValue("huid", scannedData.huid);
             formik.setFieldValue("mc_amount", scannedData.mc_amount);

             // For sales form, also set the current rate
             if (modelType === "SALE") {
               formik.setFieldValue("current_rate", scannedData.current_rate);
             }

             // Find the matching item in itemDataList to get additional details
             const matchedItem = itemDataList.find(
               (item) => item.itemid === scannedData.item_id
             );
             if (matchedItem) {
               formik.setFieldValue("item_option", {
                 label: matchedItem.itemname,
                 value: matchedItem.itemname,
               });
               formik.setFieldValue("unit", matchedItem.unit);

               if (modelType === "SALE") {
                 formik.setFieldValue("igst", matchedItem.igst);
                 formik.setFieldValue("cgst", matchedItem.cgst);
                 formik.setFieldValue("sgst", matchedItem.sgst);
               }
             }
           };

           // Update the appropriate form based on which modal is open
           if (modelType === "SALE") {
             populateFormFields(salesItemFormik);
           } else if (modelType === "RETURN") {
             populateFormFields(salesItemReturnFormik);
           }

           // Reset states after successful scan
           setBarcode("No Barcode Scanned");
           setIsScanning(false);
           setScanLoadingVisible(false);

           // Show success notification
           api.success({
             message: "QR Code Scanned Successfully",
             description: "Form fields have been populated with scanned data.",
           });
         } catch (error) {
           console.error("Error parsing QR code data:", error);
           api.error({
             message: "Invalid QR Code",
             description: "The scanned QR code is not in the expected format.",
           });
           setScanLoadingVisible(false);
           setIsScanning(false);
         }
       }
     }, [barcode, modelType, isScanning]);

     // Modify the handleScan function
     // Modify the handleScan function
     const handleScan = () => {
       setBarcode("No Barcode Scanned"); // Reset barcode before starting a new scan
       setIsScanning(true);
       setScanLoadingVisible(true);
       api.info({
         message: "Scanner Active",
         description: "Please scan a QR code to populate the form.",
       });
     };

    // Add handleStopScan function
    const handleStopScan = () => {
      setIsScanning(false);
      setScanLoadingVisible(false);
      api.info({
        message: 'Scanner Stopped',
        description: 'QR code scanning has been stopped.',
      });
    };
    
    const modalTitle = (
      <>
        {modelType === "SALE"
          ? isEdit
            ? "Update Sale Details"
            : "Enter Sale Details"
          : isEdit
          ? "Update Return Details"
          : "Enter Return Details"}
        {isScanning ? (
          <Button
            icon={<StopOutlined />}
            type="default"
            size="small"
            danger
            style={{
              marginLeft: "10px",
            }}
            onClick={handleStopScan}
          >
            Stop Scan
          </Button>
        ) : (
          <Button
            icon={<ScanOutlined />}
            type="default"
            size="small"
            ghost={true}
            style={{
              marginLeft: "10px",
              borderColor: "#1f43e5",
              color: "#1f43e5",
            }}
            onClick={handleScan}
          >
            Scan
          </Button>
        )}
      </>
    );

    const ScanLoadingOverlay = () => (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
          <div style={{ marginTop: 16 }}>Scanning QR Code...</div>
        </div>
      </div>
    );
    
    

    const wasteTypes = (form) => (
        <Select value={form.values.waste_unit} style={{ width: 60 }} onChange={(e) => form.setFieldValue("waste_unit", e)}>
          <Option value="g">g</Option>
          <Option value="%">%</Option>
        </Select>
    );

    const salesItemForm = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "60vh",
            padding: "0px",
          }}
        >
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            {/* Item Name and PCS */}
            <Row gutter={16} className="gutter-row flex-ai-center">
  <Col span={6}>
    <div>
      <span className="lbl-black">Item Name</span>
      <span className="mandatory"></span>
    </div>
  </Col>
  <Col span={10}>
    <Select
      id="item_name"
      showSearch
      placeholder="Select an Item"
      style={{ width: "100%", height: "38px" }}
      options={itemDataOptions}
      value={salesItemFormik.values.sales_item_name}
      defaultValue={salesItemFormik.values.item_option}
      onChange={(e) => {
        updateItemFields(e, salesItemFormik, false);
      }}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      virtual={false} // Setting virtual to false to ensure all options are rendered
    />
  </Col>
  <Col span={2}>
    <div>
      <span className="lbl-black">PCS</span>
    </div>
  </Col>
  <Col span={4}>
    <Input
      type="text"
      id="pcs"
      style={{ width: "100%" }}
      onChange={salesItemFormik.handleChange}
      value={salesItemFormik.values.pcs}
    />
  </Col>
</Row>

            {/* Gross Weight and Wastage */}
            <Row gutter={16} className="gutter-row flex-ai-center">
              <Col span={6}>
                <div>
                  <span className="lbl-black">Gross Weight</span>
                  <span className="mandatory"></span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="number"
                  step="0.001"
                  id="gross_weight"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    salesItemFormik.setFieldValue("gross_weight", value);
                  }}
                  value={salesItemFormik.values.gross_weight}
                />
              </Col>
              <Col span={6}>
                <div>
                  <span className="lbl-black">Wastage</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  addonAfter={wasteTypes(salesItemFormik)}
                  id="waste"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.waste}
                />
              </Col>
            </Row>

            {/* Stone Weight and Stone Cost */}
            <Row gutter={16} className="gutter-row flex-ai-center">
              <Col span={6}>
                <div>
                  <span className="lbl-black">Stone Weight</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="number"
                  step="0.001"
                  id="stone_weight"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    salesItemFormik.setFieldValue("stone_weight", value);
                  }}
                  value={salesItemFormik.values.stone_weight}
                />
              </Col>
              <Col span={6}>
                <div>
                  <span className="lbl-black">Stone Cost</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  id="stone"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.stone}
                />
              </Col>
            </Row>

            {/* Net Weight and MC */}
            <Row gutter={16} className="gutter-row flex-ai-center">
              <Col span={6}>
                <div>
                  <span className="lbl-black">Net Weight</span>
                  <span className="mandatory"></span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  id="weight"
                  disabled={true}
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.weight}
                />
              </Col>
              <Col span={6}>
                <div>
                  <span className="lbl-black">MC Amount</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  id="mc_amount"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.mc_amount}
                />
              </Col>
            </Row>

            {/* Current Market Rate and GST */}
            <Row gutter={16} className="gutter-row flex-ai-center">
              <Col span={6}>
                <div>
                  <span className="lbl-black">Current Market Rate</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  prefix="₹"
                  id="current_rate"
                  disabled={true}
                  style={{ width: "100%", padding: "7px", color: "#000000" }}
                  value={salesItemFormik.values.current_rate}
                />
              </Col>
              <Col span={6}>
                <div>
                  <span className="lbl-black">GST</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  disabled={true}
                  id="tax_amount"
                  style={{ width: "100%", color: "#000000" }}
                  value={salesItemFormik.values.igst}
                />
              </Col>
            </Row>

            {/* HUID and Others */}
            <Row gutter={16} className="gutter-row flex-ai-center">
              <Col span={6}>
                <div>
                  <span className="lbl-black">HUID</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  id="huid"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.huid}
                />
              </Col>
              <Col span={6}>
                <div>
                  <span className="lbl-black">Other Details</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  id="other_details"
                  style={{ width: "100%" }}
                  onChange={salesItemFormik.handleChange}
                  value={salesItemFormik.values.other_details}
                />
              </Col>
            </Row>

            {/* Final Amount */}
            <Row gutter={16} className="gutter-row flex-ai-center">
              <Col span={6}>
                <div>
                  <span className="lbl-black">Final Amount</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="text"
                  prefix="₹"
                  id="amount"
                  disabled={true}
                  style={{ width: "100%", padding: "7px", color: "#000000" }}
                  value={ceilToTwoDecimals(salesItemFormik.values.amount)}
                />
              </Col>
            </Row>
          </div>
        </div>
      );
    };

    const salesItemReturnForm = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "60vh", 
            padding: "0px", 
          }}
        >
          <div style={{ width: "95%", display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {/* Item Name and PCS */}
            <Row gutter={16} className="gutter-row flex-ai-center">
              <Col span={6}>
                <div>
                  <span className="lbl-black">Item Name</span>
                  <span className="mandatory"></span>
                </div>
              </Col>
              <Col span={10}>
              <Select
      id="item_name"
      showSearch
      placeholder="Select an Item"
      style={{ width: "100%", height: "38px" }}
      options={itemDataOptions}
      value={salesItemReturnFormik.values.sales_item_name}
      defaultValue={salesItemReturnFormik.values.item_option}
      onChange={(e) => {
        updateItemFields(e, salesItemReturnFormik, false);
      }}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      virtual={false} // Setting virtual to false to ensure all options are rendered
    />
              </Col>
              <Col span={2}>
                <div>
                  <span className="lbl-black">PCS</span>
                </div>
              </Col>
              <Col span={4}>
                <Input
                  type="text"
                  id="pcs"
                  style={{ width: "100%" }}
                  onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.pcs}
                />
              </Col>
            </Row>
        
              {/* Gross Weight and Wastage */}
              <Row gutter={16} className="gutter-row flex-ai-center">
                <Col span={6}>
                  <div>
                    <span className="lbl-black">Gross Weight</span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="number"
                    step="0.001"
                    id="gross_weight"
                    style={{ width: "100%" }}
                     onChange={(e) => {
                        const value = e.target.value;
                        salesItemReturnFormik.setFieldValue("gross_weight", value);
                      }}
                      value={salesItemReturnFormik.values.gross_weight}
                    />
                </Col>
                <Col span={6}>
                  <div>
                    <span className="lbl-black">Wastage</span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="text"
                    addonAfter={wasteTypes(salesItemFormik)}
                    id="waste"
                    style={{ width: "100%" }}
                    onChange={salesItemReturnFormik.handleChange}
                      value={salesItemReturnFormik.values.waste}
                    />
                </Col>
              </Row>
        
              {/* Stone Weight and Stone Cost */}
              <Row gutter={16} className="gutter-row flex-ai-center">
              <Col span={6}>
                <div>
                  <span className="lbl-black">Stone Weight</span>
                </div>
              </Col>
              <Col span={6}>
                <Input
                  type="number"
                  step="0.001"
                  id="stone_weight"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const value = e.target.value;
                    salesItemReturnFormik.setFieldValue("stone_weight", value);
                  }}
                  value={salesItemReturnFormik.values.stone_weight}
                />
              </Col>
                <Col span={6}>
                  <div>
                    <span className="lbl-black">Stone Cost</span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="text"
                    id="stone"
                    style={{ width: "100%" }}
                    onChange={salesItemReturnFormik.handleChange}
                    value={salesItemReturnFormik.values.stone}
                  />
                </Col>
              </Row>
        
              {/* Net Weight and MC */}
              <Row gutter={16} className="gutter-row flex-ai-center">
                <Col span={6}>
                  <div>
                    <span className="lbl-black">Net Weight</span>
                    <span className="mandatory"></span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="text"
                    id="weight"
                    disabled={true}
                    style={{ width: "100%" }}
                     onChange={(e) => {
                            salesItemReturnFormik.setFieldValue(
                              "weight",
                              e.target.value
                            );
                            const amount =
                              parseFloat(e.target.value || 0) *
                              parseFloat(
                                salesItemReturnFormik.values.current_rate || 0
                              );
                            salesItemReturnFormik.setFieldValue(
                              "amount",
                              amount.toFixed(3)
                            );
                          }}
                          value={salesItemReturnFormik.values.weight}
                        />
                </Col>
                <Col span={6}>
                  <div>
                    <span className="lbl-black">MC Amount</span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="text"
                    id="mc_amount"
                    style={{ width: "100%" }}
                    onChange={salesItemReturnFormik.handleChange}
                      value={salesItemReturnFormik.values.mc_amount}
                    />
                </Col>
              </Row>
        
              {/* Current Market Rate and GST */}
              <Row gutter={16} className="gutter-row flex-ai-center">
                <Col span={6}>
                  <div>
                    <span className="lbl-black">Current Market Rate</span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="text"
                    prefix="₹"
                    id="current_rate"
                    style={{ width: "100%", padding: "7px", color: "#000000" }}
                   value={salesItemReturnFormik.values.current_rate}
                          onChange={salesItemReturnFormik.handleChange}
                        />
                </Col>
              </Row>
        
              {/* HUID and Others */}
              <Row gutter={16} className="gutter-row flex-ai-center">
                <Col span={6}>
                  <div>
                    <span className="lbl-black">HUID</span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="text"
                    id="huid"
                    style={{ width: "100%" }}
                    onChange={salesItemReturnFormik.handleChange}
                      value={salesItemReturnFormik.values.huid}
                    />
                </Col>
                <Col span={6}>
                  <div>
                    <span className="lbl-black">Other Details</span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="text"
                    id="other_details"
                    style={{ width: "100%" }}
                    onChange={salesItemReturnFormik.handleChange}
                      value={salesItemReturnFormik.values.other_details}
                    />
                </Col>
              </Row>
        
              {/* Final Amount */}
              <Row gutter={16} className="gutter-row flex-ai-center">
                <Col span={6}>
                  <div>
                    <span className="lbl-black">Amount</span>
                  </div>
                </Col>
                <Col span={6}>
                  <Input
                    type="text"
                    prefix="₹"
                    id="amount"
                    disabled={true}
                    style={{ width: "100%", padding: "7px", color: "#000000" }}
                    onChange={salesItemReturnFormik.handleChange}
                  value={salesItemReturnFormik.values.amount}
                    />              
                </Col>
              </Row>
              </div>
        </div>
      );
  };


    const salesForm = () => {
        return (
            <>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={4} offset={14} className="gutter-box flex-je-ac">
                        <span className="saleBillText">Items total amount</span>                       
                    </Col>
                    <Col span={6} className="gutter-box">
                        <InputNumber
                            prefix='₹'
                            precision={3}
                            disabled={true}
                            id="items_amount"
                            className='input-number-with-prefix'
                            onChange={formik.handleChange}
                            value={formik.values.items_amount}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={4} offset={14} className="gutter-box flex-je-ac">
                        <span className="saleBillText">Returns total amount</span>                      
                    </Col>
                    <Col span={6} className="gutter-box">
                        <InputNumber
                            prefix='₹'
                            precision={3}
                            disabled={true}
                            id="return_items_amount"
                            className='input-number-with-prefix'
                            onChange={formik.handleChange}
                            value={formik.values.return_items_amount}
                        /> 
                    </Col>
                </Row>
                <Row gutter={16} justify={"end"} className="gutter-row">
                    <Col span={8} className="gutter-box" style={{display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'start'}}>
                        <Statistic 
                            prefix='₹'
                            title="Total Amount" 
                            value={formik.values.total_amount} 
                            precision={3}
                            style={{textAlign:'right', border: '1px dotted #d3d3d3', padding: '10px'}}
                            valueStyle={{ width: '275px', fontSize: '28px', textAlign:'right', color: '#000000', fontWeight: 'bolder'}} />
                    </Col>
                </Row>
            </>
        )
    }

    const salesContainer = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesItemList.length === 0 ? 'center' : 'right'}}>
                        <span className="saleBillText" style={{marginRight: '15px', fontSize: '13px', display: salesItemList.length === 0 ? 'inline' : 'none'}}>
                            No sale item added so far, click here to add
                        </span>
                    </Col>                  
                </Row>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesItemList.length === 0 ? 'center' : 'right'}}>
                        <Button 
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => showModal('SALE')}
                            style={{ width: '50px', height: '36px', fontFamily: 'poppins', fontSize: '15px'}}>
                        </Button>
                    </Col>                  
                </Row>
                {salesItemTable(salesItemList)}
            </>
        )
    }

    const salesReturnContainer = () => {
        return (
            <>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesReturnItemList.length === 0 ? 'center' : 'right'}}>
                        <span className="saleBillText" style={{marginRight: '15px', fontSize: '13px', display: salesReturnItemList.length === 0 ? 'inline' : 'none'}}>
                            No sale return item added so far, click here to add
                        </span>
                    </Col>                  
                </Row>
                <Row gutter={16} className="gutter-row" justify="center" style={{margin: '0px 0px'}}>
                    <Col span={24} className="gutter-box" style={{textAlign: salesReturnItemList.length === 0 ? 'center' : 'right'}}>
                        <Button 
                            type='primary'
                            icon={<PlusOutlined />}
                            onClick={() => showModal('RETURN')}
                            style={{ width: '50px', height: '36px', fontFamily: 'poppins', fontSize: '15px'}}>
                        </Button>
                    </Col>                  
                </Row>
                {salesItemTable(salesReturnItemList)}
            </>
        )
    }

    const createNewFormSection = () => {
		return (
			<>
                {salesOrderForm()}
                <Divider orientation='left' orientationMargin='0'>Sales</Divider>
                {salesContainer()}
                <Divider orientation='left' orientationMargin='0'>Sales Return</Divider>
                {salesReturnContainer()}
				{/* <Collapse 
                    style={{marginTop: '30px'}} 
                    defaultActiveKey={['1']} 
                    items={[
                        {
                            key: '1',
                            label: 'SALES',
                            children: salesContainer(),
                        }
                    ]}>
                </Collapse>
				<Collapse 
                    style={{marginTop: '20px'}} 
                    defaultActiveKey={['1']} 
                    items={[
                        {
                            key: '1',
                            label: 'OLD GOLD RETURN',
                            children: salesReturnContainer(),
                        }
                    ]}>
                </Collapse> */}
                <Card style={{marginTop: '35px'}}>
                    {salesForm()}
                </Card>                
			</>
		);
	};

    const getCurrentRate = (type) => itemTypes.find(it => it.name.toLowerCase() === type.toLowerCase())?.current_rate;

    const salesAmountWOTax = () => parseFloat(formik.values.items_amount) - salesItemList.reduce((acc, next) => acc + parseFloat(next.tax_amount), 0);

    const totalOldGoldWeight = () => salesReturnItemList.reduce((acc, next) => parseFloat(acc) + parseFloat(next.weight), 0);  

    const getPageMargins = () => {
        return `
            @page { 
                size: 100mm 200mm; 
                margin: 5mm;
            }
            @media print {
                .print-header, .print-footer {
                    display: none !important;
                }
                body::before, body::after {
                    display: none !important;
                }
            }
        `;
    };
    
    
    const formatValue = (value, unit = "") => {
      const parsedValue = parseFloat(value);
      if (isNaN(parsedValue) || value === null || value === undefined) {
          return `0${unit}`;
      }
      return `${parsedValue.toFixed(3)}${unit}`;
  };
  
    
    const calculateAmounts = (si) => {
        const grossWeight = parseFloat(si.gross_weight || 0);
        const stoneWeight = parseFloat(si.stone_weight || 0);
        const netWeight = grossWeight - stoneWeight;
    
        const wasteUnit = si.waste_unit || 'g';
        const waste = wasteUnit === '%' ? (netWeight * parseFloat(si.waste || 0)) / 100 : parseFloat(si.waste || 0);
        const totalWeight = netWeight + waste;
    
        const currentRate = parseFloat(si.current_rate || 0);
        const totalAmount = totalWeight * currentRate;
    
        const stoneCost = parseFloat(si.stone || 0);
        const mcAmount = parseFloat(si.mc_amount || 0);
    
        const preFinalAmount = totalAmount + stoneCost + mcAmount;
    
        const cgst = (preFinalAmount * si.cgst) / 100;
        const sgst = (preFinalAmount * si.sgst) / 100;
    
        const finalAmount1 = preFinalAmount + cgst + sgst;
        const finalAmount=Math.round(finalAmount1);
    
        return {
            grossWeight,
            currentRate,
            stoneWeight,
            netWeight,
            waste,
            totalWeight,
            totalAmount,
            preFinalAmount,
            finalAmount,
            finalAmount1 ,
            cgst,
            sgst,
        };
    };
    const EstimationReport = () => {
      const currentRate = salesItemList.map((si) => si.current_rate);

      return (
        <>
          <div style={{ display: "none" }}>
            <div
              style={{ display: "flex", justifyContent: "center" }}
              className="estimation-container"
              ref={componentRef}
            >
              <style>{getPageMargins()}</style>
              <div style={{ textAlign: "center", width: "100%" }}>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    paddingTop: "15px",
                  }}
                >
                  Quotation
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontSize: "14px" }}>Name:SLJ</span>
                  <span style={{ fontSize: "14px" }}>
                    Date: {getCurrentDate1()}
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    paddingTop: "5px",
                    display: "flex",
                    justifyContent: "flex-end", // Changed from alignItems:'right' to justifyContent:'flex-end'
                  }}
                >
                  Rate: ₹{parseFloat(currentRate).toFixed(2)}
                </div>
                <hr style={{ border: "1px dashed #d3d3d3" }} />
                {salesItemList.map((si) => {
                  const amounts = calculateAmounts(si);

                  return (
                    <div key={si.item_id}>
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            Gross wt:
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            {formatValue(si.gross_weight, " gm")}
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            stone Wt:
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            -{formatValue(si.stone_weight, " gm")}
                          </span>
                        </Col>
                      </Row>
                      <hr
                        style={{
                          border: "1px dashed #d3d3d3",
                          margin: "10px 0",
                        }}
                      />
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            Net wt:
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            {parseFloat(si.weight).toFixed(3)} gm
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            Waste:
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            + {formatValue(si.waste, ` ${si.waste_unit}`)}
                          </span>
                        </Col>
                      </Row>
                      <hr style={{ border: "1px dashed #d3d3d3" }} />

                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            Total Wt :
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            {parseFloat(amounts.totalWeight).toFixed(3)}gm
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            Rate :
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            X {si.current_rate}/gm
                          </span>
                        </Col>
                      </Row>
                      <hr
                        style={{
                          border: "1px dashed #d3d3d3",
                          margin: "10px 0",
                        }}
                      />

                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            Amount :
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            {parseFloat(amounts.totalAmount).toFixed(2)}
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            Stone cost:
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            ₹{parseFloat(formatValue(si.stone)).toFixed(2)}
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            MC amount:
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            + ₹{parseFloat(formatValue(si.mc_amount)).toFixed(2)}

                          </span>
                        </Col>
                      </Row>
                      <hr
                        style={{
                          border: "1px dashed #d3d3d3",
                          margin: "10px 0",
                        }}
                      />
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            Total Amount :
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            {parseFloat(amounts.preFinalAmount).toFixed(2)}
                          </span>
                        </Col>
                      </Row>
                      <hr
                        style={{
                          border: "1px dashed #d3d3d3",
                          margin: "10px 0",
                        }}
                      />
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            CGST amount:
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            ₹{parseFloat(formatValue(amounts.cgst)).toFixed(2)}
                          </span>
                        </Col>
                      </Row>
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "600" }}
                          >
                            SGST amount:
                          </span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span className="saleBillText">
                            ₹{parseFloat(formatValue(amounts.sgst)).toFixed(2)}
                          </span>
                        </Col>
                      </Row>
                      <hr
                        style={{
                          border: "1px dashed #d3d3d3",
                          margin: "10px 0",
                        }}
                      />
                      <Row gutter={16} style={{ paddingTop: "5px" }}>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "700", fontSize: "16px" }}
                          ></span>
                        </Col>
                        <Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <span
                            className="saleBillText"
                            style={{ fontWeight: "700", fontSize: "16px" }}
                          >
                            ₹{parseFloat(amounts.finalAmount1).toFixed(2)}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
                <hr
                  style={{ border: "1px dashed #d3d3d3", margin: "10px 0" }}
                />

                <Row gutter={16} style={{ paddingTop: "5px" }}>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <span
                      className="saleBillText"
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      Final amount:
                    </span>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <span
                      className="saleBillText"
                      style={{
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      ₹
                      {parseFloat(
                        salesItemList.reduce(
                          (acc, next) =>
                            acc +
                            parseFloat(calculateAmounts(next).finalAmount),
                          0
                        )
                      ).toFixed(2)}
                    </span>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </>
      );
    };

    
return (
  <>
    <PageTitle
      imagesrc={SaleImage}
      pageTitle={isUpdate ? "Update Quotation" : "Create Quotation"}
      buttonList={[
        {
          buttonName: "Back",
          className: "btn-back",
          url: "/slj/quotation",
        },
      ]}
    />
    {contextHolder}
    <div className="content-area">
      <div className="formAreaStyle">{createNewFormSection()}</div>
    </div>
    {EstimationReport()}
    <div className="formSubmitArea">
      <div className="formSubmitInnerArea">
        <Button
          icon={<FileImageOutlined />}
          type="primary"
          size="large"
          style={{
            width: "150px",
            backgroundColor: "green",
            borderRadius: "4px",
          }}
          onClick={() => {
            if (hasValidated(formik.values)) {
              handlePrint();
            }
          }}
        >
          Quotation
        </Button>
        <Button
          icon={<ClearOutlined />}
          type="primary"
          size="large"
          style={{
            width: "150px",
            backgroundColor: "grey",
            borderRadius: "4px",
          }}
          onClick={formik.handleReset}
        >
          Reset Values
        </Button>
        <Button
          icon={<SaveOutlined />}
          type="primary"
          size="large"
          style={{
            width: "150px",
            backgroundColor: "#1f43e5",
            borderRadius: "4px",
          }}
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>

    {/* Sales/Return Modal with Scan Button */}
    <Modal
  title={modalTitle}
  closable={false}
  maskClosable={false}
  keyboard={false}
  open={modelType === "SALE" || modelType === "RETURN"}
  okText={
    modelType === "SALE"
      ? isEdit
        ? "Update Sale Details"
        : "Add Sale Details"
      : isEdit
      ? "Update Return Details"
      : "Add Return Details"
  }
  onOk={() => handleOk(modelType === "SALE")}
  width={"800px"}
  onCancel={handleCancel}
>
  <div style={{ position: 'relative' }}>
    {scanLoadingVisible && <ScanLoadingOverlay />}
    {modelType === "SALE" ? salesItemForm() : salesItemReturnForm()}
  </div>
</Modal>
    {/* New Customer Modal */}
    <Modal
      title="New Customer"
      closable={false}
      maskClosable={false}
      keyboard={false}
      open={modelType === "NEW_CUSTOMER"}
      okText={"Create Customer"}
      onOk={() => {
        createCustomer();
      }}
      width={"500px"}
      onCancel={handleCancel}
    >
      <div>{newCustomer()}</div>
    </Modal>
  </>
);

}

export default QuotationForm;