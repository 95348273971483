import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Collapse, DatePicker, Divider, Flex, Input, InputNumber, Modal, Row, Select, Space, Statistic, Switch, Table, Upload, notification } from 'antd';
import { CheckCircleFilled, ClearOutlined, DeleteFilled, EyeOutlined, LoadingOutlined, PlusOutlined, ProfileOutlined, ProfileTwoTone, SaveOutlined } from '@ant-design/icons';
import SaleImage from '../../assets/images/plant.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

const {Option} = Select;

const PlantForm = () => { 
	const location = useLocation();
	const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [accountList, setAccountList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    
    const formik = useFormik({
      initialValues: {
          plant_code: '',
          plant_name: '',
          email: '',
          phone_no: '',
          website: '',
          address: '',
          country: '',
          state: '',
          city: '',
          zipcode: '',
          gst_register_type: '',
          gst_no: '',
          account_no: '',
      },
      enableReinitialize: true, // This ensures formik reinitializes on initialValues change
      onSubmit: (values, { resetForm }) => {
          if (values.plant_code === '' || values.plant_name === '' || values.phone_no === '' || values.address === '' || values.zipcode === '') {
              openErrorNotification('Please Enter Required Fields...');
              return;
          }
  
          if (!/^\d{10}$/.test(values.phone_no)) {
              openErrorNotification('Contact number must be exactly 10 digits.');
              return;
          }
  
          if (location?.state?.id) {
              updateSaleData(values);
          } else {
              createPlant(values, resetForm);
          }
      },
  });
  
        
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const uploadButton = (
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button"
        >
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </button>
      );

      const checkDuplicateEntry = async (values) => {
        try {
            const response = await httpCall({
                method: 'POST',
                url: '/api/plant/check-duplicate',
                payload: {
                    phone_no: values.phone_no,
                    plant_name: values.plant_name,
                    plant_code: values.plant_code
                },
            });
            return response.isDuplicate;
        } catch (error) {
            console.error('Error checking for duplicate:', error);
            return false;
        }
    };

    useEffect(() => {
      const fetchData = async () => {
          try {
              if (location?.state?.id) {
                  const response = await httpCall({
                      method: 'GET',
                      url: '/api/plant/list?plant_code=' + location?.state?.id,
                  });
                  if (response.data.length > 0) {
                      formik.setValues(response.data[0]);
                  }
              }
          } catch (error) {
              console.error('Error fetching plant details:', error);
          }
      };
      fetchData();
  }, [location?.state?.id]);
  
  

  useEffect(() => {
    const fetchAccountList = async () => {
        setLoading(true);
        try {
            const response = await httpCall({
                method: 'GET',
                url: '/api/account/list',
            });
            if (response.data) {
                setAccountList(response.data.map(acc => ({ label: `${acc.bank_name} - ${acc.account_no}`, value: acc.account_no })));
            }
        } catch (error) {
            console.error('Error fetching account list:', error);
        } finally {
            setLoading(false);
        }
    };
    fetchAccountList();
}, []);


	const openNotification = (description) => {
		api.open({
		  message: 'Success !',
		  duration: 2,		  
		  description: description,
		  icon: <CheckCircleFilled style={{ color: '#808000' }} />,		  
		});
	};

    const openErrorNotification = (description) => {
		api.error({
		  message: 'Error !',
		  duration: 4,		  
		  description: description,
		});
	};


        const createPlant = async (values, handleReset) => {
            const isDuplicate = await checkDuplicateEntry(values);
            if (isDuplicate) {
                api.error({
                    message: "Duplicate Entry Error",
                    description: "A plant with this Contact Number, Plant Name, or Plant Code already exists.",
                    duration: 4,
                });
                return; 
            }
        
            httpCall({
                method: 'POST',
                url: '/api/plant/create',
                payload: { ...values },
            })
                .then((response) => {
                    if (response) {
                        openNotification(
                            'Plant Created Successfully, Go to list view or create another one.'
                        );
                        handleReset(); 
                    }
                })
                .catch((error) => {
                    console.error(error);
                    
                        api.error({
                            message: "Duplicate Entry Error",
                            description: "A plant with this Contact Number, Plant Name, or Plant Code already exists.",
                            duration: 4,
                        });
                    })}
        
    
                    const updateSaleData = async (values) => {
                        try {
                            const response = await httpCall({
                                method: 'PUT',
                                url: '/api/plant/update',
                                payload: { ...values, plant_code: location?.state?.id },
                            });
                    
                            if (response && response.status === 200) {
                                openNotification('Plant Details Updated Successfully, Goto list view.');
                                setTimeout(() => navigate('/slj/plant'), 2500);
                            } else {
                                throw new Error(response.data.error || 'Failed to update plant data.');
                            }
                        } catch (error) {
                            // Extract error message and status code
                            const errorMessage = error.response?.data?.error || error.message || 'An error occurred while updating the plant.';
                            const statusCode = error.response?.status;
                    
                            // Check if the status code indicates a bad request and if the error message indicates a duplicate entry
                            if (statusCode === 400 && errorMessage.includes('already exists')) {
                                // Show a notification specifically for duplicate entry
                                api.error({
                                    message: 'Duplicate Entry Error',
                                    description: 'A plant with this Contact Number already exists.',
                                    duration: 4,
                                });
                            } else {
                                // Handle other errors
                                api.error({
                                    message: 'Update Failed',
                                    description: errorMessage,
                                    duration: 4,
                                });
                            }
                            console.error(error);
                        }
                    };
                    

    const createNewFormSection = () => {
		return (
      <>
        <Row gutter={16} className="gutter-row">
          <Col span={4} className="gutter-box">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              style={{ padding: "10px" }}
              // showUploadList={false}
              fileList={fileList}
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onPreview={handlePreview}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </Col>
          <Col span={6} offset={2} className="gutter-box">
            <div>
              <span className="hintText">Plant Code</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="text"
              id="plant_code"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.plant_code}
            />
          </Col>
          <Col span={12} className="gutter-box">
            <div>
              <span className="hintText">Plant Name</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="text"
              id="plant_name"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.plant_name}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">Email</span>
            </div>
            <Input
              type="text"
              id="email"
              style={{ width: "100%" }}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">Contact No</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="text"
              id="phone_no"
              style={{ width: "100%", color: "#000000" }}
              value={formik.values.phone_no}
              onChange={(e) => {
                // Allow only numeric values
                const numericValue = e.target.value.replace(/\D/g, "");
                formik.setFieldValue("phone_no", numericValue);
              }}
            />
          </Col>

          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">Website</span>
            </div>
            <Input
              type="text"
              id="website"
              style={{ width: "100%", color: "#000000" }}
              value={formik.values.website}
              onChange={formik.handleChange}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">Address</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="text"
              id="address"
              style={{ width: "100%", color: "#000000" }}
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">Country</span>
            </div>
            <Input
              type="text"
              id="country"
              style={{ width: "100%", color: "#000000" }}
              value={formik.values.country}
              onChange={formik.handleChange}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">State</span>
            </div>
            <Input
              type="text"
              id="state"
              style={{ width: "100%", color: "#000000" }}
              value={formik.values.state}
              onChange={formik.handleChange}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">City</span>
            </div>
            <Input
              type="text"
              id="city"
              style={{ width: "100%", color: "#000000" }}
              value={formik.values.city}
              onChange={formik.handleChange}
            />
          </Col>
          <Col span={6} className="gutter-box">
            <div>
              <span className="hintText">ZipCode</span>
              <span className="mandatory"></span>
            </div>
            <Input
              type="text"
              id="zipcode"
              style={{ width: "100%", color: "#000000" }}
              value={formik.values.zipcode}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
        <Row gutter={16} className="gutter-row">
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">GST Register Type</span>
            </div>
            <Select
              id="gst_register_type"
              showSearch
              placeholder="Select Type"
              style={{ width: "100%", height: "38px" }}
              options={[
                { label: "Regular", value: "Regular" },
                { label: "Composition", value: "Composition" },
                { label: "Unregistered", value: "Unregistered" },
                { label: "Consumer", value: "Consumer" },
              ]}
              value={formik.values.gst_register_type}
              onChange={(e) => {
                formik.setFieldValue("gst_register_type", e);
              }}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">GST No</span>
            </div>
            <Input
              type="text"
              id="gst_no"
              style={{ width: "100%", color: "#000000" }}
              value={formik.values.gst_no}
              onChange={formik.handleChange}
            />
          </Col>
          <Col span={8} className="gutter-box">
            <div>
              <span className="hintText">Bank & Account</span>
            </div>
            <Select
              id="account_no"
              showSearch
              placeholder="Select Account"
              style={{ width: "100%", height: "38px" }}
              options={accountList}
              value={formik.values.account_no}
              onChange={(e) => {
                formik.setFieldValue("account_no", e);
              }}
            />
          </Col>
        </Row>
      </>
    );
	};

	return (
		<>
		<PageTitle
			imagesrc={SaleImage}
			pageTitle={location?.state?.id ? "Update Plant" : "Create Plant"}
			buttonList={[{
				buttonName: 'Back',
				className: 'btn-back',
				url: '/slj/plant'
			}]}
		/>
		{contextHolder}
			<div className="content-area">
				<div className="formAreaStyle">
					{ createNewFormSection() }		
				</div>
			</div>
			<div className="formSubmitArea">
    <div className="formSubmitInnerArea">
        <Button
            icon={<ClearOutlined />}
            type="primary"
            size="large"
            style={{ width: '150px', backgroundColor: 'grey', borderRadius: '4px' }}
            onClick={formik.handleReset}
        >
            Reset Values
        </Button>
        <Button
            icon={<SaveOutlined />}
            type="primary"
            size="large"
            style={{ width: '150px', backgroundColor: '#1f43e5', borderRadius: '4px' }}
            onClick={formik.handleSubmit}
        >
            Submit
        </Button>
    </div>
</div>

		</>
	);


}

export default PlantForm;