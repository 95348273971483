import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Popconfirm, Row, Select, Space, Spin, Table, Tabs, Tag, notification } from 'antd';
import PageTitle from '../common/PageTitle';
import SalesImage from '../../assets/images/sales.png';
import columnSearch from '../common/columnSearch';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { useFormik } from 'formik';
import moment from 'moment';
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, FolderOpenOutlined, OpenAIOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

const StockReport = () => {
    const navigate = useNavigate();
    const [stockReportData, setStockReportData] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);
    const [totals, setTotals] = useState({
        opg_qty: 0,
        opg_wgt: 0,
        inwd_qty: 0,
        inwd_wgt: 0,
        outwd_qty: 0,
        outwd_wgt: 0,
        cl_qty: 0,
        cl_wgt: 0
    });

    const formik = useFormik({
        initialValues: {
            from_date: moment().format('YYYY-MM-DD'),
            to_date: moment().format('YYYY-MM-DD')
        },
        onSubmit: (values, { resetForm }) => {
        },
    });

    useEffect(() => {
        fetchStockReport(formik.values.from_date, formik.values.to_date);
    }, []);

    // Update totals whenever stockReportData changes
    useEffect(() => {
        calculateTotals();
    }, [stockReportData]);

    const getValue = (list, key, id) => {
        const obj = list.filter(o => o.itemid === id);
        if (obj.length > 0) {
            return obj[0][key];
        }
        return 0;
    }

    const calculateTotals = () => {
        const newTotals = stockReportData.reduce((acc, item) => {
            return {
                opg_qty: acc.opg_qty + Number(item.opg_qty || 0),
                opg_wgt: acc.opg_wgt + Number(item.opg_wgt || 0),
                inwd_qty: acc.inwd_qty + Number(item.inwd_qty || 0),
                inwd_wgt: acc.inwd_wgt + Number(item.inwd_wgt || 0),
                outwd_qty: acc.outwd_qty + Number(item.outwd_qty || 0),
                outwd_wgt: acc.outwd_wgt + Number(item.outwd_wgt || 0),
                cl_qty: acc.cl_qty + Number(item.cl_qty || 0),
                cl_wgt: acc.cl_wgt + Number(item.cl_wgt || 0)
            };
        }, {
            opg_qty: 0,
            opg_wgt: 0,
            inwd_qty: 0,
            inwd_wgt: 0,
            outwd_qty: 0,
            outwd_wgt: 0,
            cl_qty: 0,
            cl_wgt: 0
        });

        setTotals(newTotals);
    };

    const fetchStockReport = (from, to) => {
        setLoading(true);
        httpCall({
            method: 'GET',
            url: `/api/stock/report?from=${from}&to=${to}`,
        })
            .then((response) => {
                const stocks = response?.data.stocks;
                const inwards = response?.data.inwards;
                const outwards = response?.data.outwards;
                const stockReport = stocks.map(stock => {
                    const outwd_qty = getValue(outwards, 'outwd_qty', stock.item_id);
                    const outwd_wgt = getValue(outwards, 'outwd_wgt', stock.item_id);
                    const inwd_qty = getValue(inwards, 'inwd_qty', stock.item_id);
                    const inwd_wgt = getValue(inwards, 'inwd_wgt', stock.item_id);

                    return {
                        itemid: stock.item_id,
                        itemname: stock.itemname,
                        opg_qty: parseFloat(stock.pcs) + parseFloat(outwd_qty) - parseFloat(inwd_qty),
                        opg_wgt: parseFloat(stock.weight) + parseFloat(outwd_wgt) - parseFloat(inwd_wgt),
                        inwd_qty: inwd_qty,
                        inwd_wgt: inwd_wgt,
                        outwd_qty: outwd_qty,
                        outwd_wgt: outwd_wgt,
                        cl_qty: stock.pcs,
                        cl_wgt: stock.weight,
                    }
                });

                setStockReportData(stockReport);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error retrieving sales order data:', error);
            });
    };

    const stockReportColumns = [
        {
            title: 'Item Name',
            dataIndex: 'itemname',
            key: 'itemname',
            align: 'center',
            width: 100,
            sorter: (a, b) => a.itemname.localeCompare(b.itemname),
            defaultSortOrder: 'ascend',
        },
        {
            title: <>Opening Stock <FolderOpenOutlined /></>,
            align: 'center',
            children: [
                {
                    title: 'Quantity',
                    dataIndex: 'opg_qty',
                    key: 'opg_qty',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.opg_qty - b.opg_qty,
                    render: (text) => Number(text).toFixed(2)
                },
                {
                    title: 'Weight (g)',
                    dataIndex: 'opg_wgt',
                    key: 'opg_wgt',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.opg_wgt - b.opg_wgt,
                    render: (text) => Number(text).toFixed(2)
                }
            ]
        },
        {
            title: <>Inward Stock <ArrowDownOutlined /></>,
            align: 'center',
            children: [
                {
                    title: 'Quantity',
                    dataIndex: 'inwd_qty',
                    key: 'inwd_qty',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.inwd_qty - b.inwd_qty,
                    render: (text) => Number(text).toFixed(2)
                },
                {
                    title: 'Weight (g)',
                    dataIndex: 'inwd_wgt',
                    key: 'inwd_wgt',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.inwd_wgt - b.inwd_wgt,
                    render: (text) => Number(text).toFixed(2)
                }
            ]
        },
        {
            title: <>Outward Stock <ArrowUpOutlined /></>,
            align: 'center',
            children: [
                {
                    title: 'Quantity',
                    dataIndex: 'outwd_qty',
                    key: 'outwd_qty',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.outwd_qty - b.outwd_qty,
                    render: (text) => Number(text).toFixed(2)
                },
                {
                    title: 'Weight (g)',
                    dataIndex: 'outwd_wgt',
                    key: 'outwd_wgt',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.outwd_wgt - b.outwd_wgt,
                    render: (text) => Number(text).toFixed(2)
                }
            ]
        },
        {
            title: <>Closing Stock <CloseOutlined /></>,
            align: 'center',
            children: [
                {
                    title: 'Quantity',
                    dataIndex: 'cl_qty',
                    key: 'cl_qty',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.cl_qty - b.cl_qty,
                    render: (text) => Number(text).toFixed(2)
                },
                {
                    title: 'Weight (g)',
                    dataIndex: 'cl_wgt',
                    key: 'cl_wgt',
                    align: 'center',
                    width: 30,
                    sorter: (a, b) => a.cl_wgt - b.cl_wgt,
                    render: (text) => Number(text).toFixed(2)
                }
            ]
        },
    ];

    return (
        <>
            <PageTitle
                imagesrc={SalesImage}
                pageTitle="Daily Stock Statement"
                buttonList={[]}
            />
            {contextHolder}
            <div className='vendorListArea'>
                <Row gutter={16} className="gutter-row" style={{ marginBottom: '30px' }}>
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Select Date Range</span>
                        </div>
                        <RangePicker 
                            style={{ height: '38px' }} 
                            onChange={(e) => {
                                if (!!e) {
                                    const start = e[0].format('YYYY-MM-DD');
                                    const end = e[1].format('YYYY-MM-DD');
                                    fetchStockReport(start, end)
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                    <Row gutter={16} className="gutter-row" style={{ marginTop: '-20px', marginBottom: '50px' }}>
                        <Col span={24} className="gutter-box">
                            <span style={{ fontSize: '15px', fontWeight: '600' }}>Stock Statement</span>
                            <Table
                                dataSource={stockReportData}
                                size='small'
                                bordered={true}
                                columns={stockReportColumns}
                                rowKey="itemid"
                                scroll={{ y: '250px' }}
                                pagination={false}
                                summary={() => (
                                    <Table.Summary fixed>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0} align="center">
                                                <strong>Total</strong>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} align="center">
                                                <strong>{totals.opg_qty.toFixed(2)}</strong>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2} align="center">
                                                <strong>{totals.opg_wgt.toFixed(2)}</strong>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={3} align="center">
                                                <strong>{totals.inwd_qty.toFixed(2)}</strong>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} align="center">
                                                <strong>{totals.inwd_wgt.toFixed(2)}</strong>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={5} align="center">
                                                <strong>{totals.outwd_qty.toFixed(2)}</strong>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={6} align="center">
                                                <strong>{totals.outwd_wgt.toFixed(2)}</strong>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={7} align="center">
                                                <strong>{totals.cl_qty.toFixed(2)}</strong>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={8} align="center">
                                                <strong>{totals.cl_wgt.toFixed(2)}</strong>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                )}
                                style={{ fontFamily: 'Poppins', marginTop: '10px' }}
                            />
                        </Col>
                    </Row>
                </Spin>
            </div>
        </>
    );
};

export default StockReport;