import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, DatePicker, Row, Select, Spin, Table, Card } from 'antd';
import PageTitle from '../common/PageTitle';
import salesreportImage from '../../assets/images/sales_report.svg';
import httpCall from '../../utils/api';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import '../../css/Vendor.css';
import { useFormik } from 'formik';
import moment from 'moment';

const { RangePicker } = DatePicker;

const SaleReport = () => {
    const navigate = useNavigate();
    const [salesOrderList, setSalesOrderList] = useState([]);
    const [salesReceiptList, setSalesReceiptList] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    
    useEffect(() => {
        setLoading(true);
        fetchCustomerList();
    }, []);

    const formik = useFormik({
        initialValues: {
            customer_id: '',
            from_date: '',
            to_date: ''
        },
        onSubmit: () => {},
    });

    useEffect(() => {
        if(formik.values.customer_id) {
            setLoading(true);
            Promise.all([
                fetchSalesOrderList(formik.values.customer_id),
                fetchSalesReceiptList(formik.values.customer_id)
            ]).then(() => {
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        } else {
            setReportData([]);
            setTotalAmount(0);
        }
    }, [formik.values.customer_id]);

    useEffect(() => {
        let combinedData = [...salesOrderList, ...salesReceiptList];
        
        if (formik.values.from_date && formik.values.to_date) {
            const startDate = moment(formik.values.from_date).startOf('day');
            const endDate = moment(formik.values.to_date).endOf('day');
            
            combinedData = combinedData.filter(item => {
                const itemDate = moment(item.date);
                return itemDate.isBetween(startDate, endDate, 'day', '[]');
            });
        }

        const total = combinedData.reduce((sum, item) => sum + (item.amount || 0), 0);
        setTotalAmount(total);
        setReportData(combinedData);
    }, [salesOrderList, salesReceiptList, formik.values.from_date, formik.values.to_date]);


    const fetchSalesOrderList = (customer_id) => {
        return httpCall({
            method: 'GET',
            url: '/api/sales/order/report/' + customer_id,
        })
            .then((response) => {
                setSalesOrderList(response?.data || []);
            })
            .catch((error) => {
                console.error('Error retrieving sales order data:', error);
                setSalesOrderList([]);
            });
    };

    const fetchSalesReceiptList = (customer_id) => {
        return httpCall({
            method: 'GET',
            url: '/api/sales/receipt/report/' + customer_id,
        })
            .then((response) => {
                setSalesReceiptList(response?.data || []);
            })
            .catch((error) => {
                console.error('Error retrieving sales data:', error);
                setSalesReceiptList([]);
            });
    };

    const fetchCustomerList = () => {
        httpCall({
            method: 'GET',
            url: '/api/customer/get',
        })
            .then((response) => {
                if (response) {
                    setCustomerList(response?.data.map(c => ({label: c.company_name, value: c.id})));                    
                } else {
                    console.error(
                        'Error retrieving customer list:',
                        response.message
                    );
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error retrieving customer list:', error);
                setLoading(false);
            });
    };

    const handleDateChange = (dates) => {
        if (dates) {
            const [start, end] = dates;
            formik.setFieldValue('from_date', start.format('YYYY-MM-DD'));
            formik.setFieldValue('to_date', end.format('YYYY-MM-DD'));
        } else {
            formik.setFieldValue('from_date', '');
            formik.setFieldValue('to_date', '');
        }
    };

    const columnsNew = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            width: 150,
            sorter: (a, b) => moment(a.date) - moment(b.date),
            defaultSortOrder: 'ascend',
            render: (_, record) => {
                return moment(record.date).format('DD-MM-YYYY');
            }
        },
        {
            title: 'Particulars',
            dataIndex: 'vch_type',
            key: 'vch_type',
            align: 'center',
            width: 300
        },
        {
            title: 'Vch Type',
            dataIndex: 'vch_type',
            key: 'vch_type',
            align: 'center',
            width: 75,
            render: (_, record) => {
                return (
                    <span style={{fontSize: '13px', fontWeight: '700'}}>
                        {record.vch_type.toUpperCase()}
                    </span>
                )
            }
        },
        {
            title: 'Vch No',
            dataIndex: 'vch_no',
            key: 'vch_no',
            align: 'center',
            width: 100
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            width: 100,
            render: (_, record) => {
                return record.amount > 0 ? record.amount.toFixed(2) : '';
            }
        }
    ];

    const mergedColumns = React.useMemo(() => {
        return columnsNew.map((col) => ({
            ...col,
            onCell: undefined,
        }));
    }, []);

	return (
        <>
            <PageTitle
                imagesrc={salesreportImage}
                pageTitle="Sales Report"
                buttonList={[]}
            />
            <div className='vendorListArea' style={{overflow: ''}}>
                <Row gutter={16} className="gutter-row">
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Customer</span>
                        </div>                                
                        <Select
                            id="customer_id"
                            showSearch
                            placeholder="Select Customer"
                            style={{ width: '100%', height: '38px' }}
                            options={customerList}
                            onChange={(e)=> {
                                formik.setFieldValue("customer_id", e);
                            }}
                            allowClear
                        />
                    </Col>
                    <Col span={12} className="gutter-box">
                        <div>
                            <span className="hintText color-black">Select Date Range</span>
                        </div> 
                        <RangePicker 
                            style={{height: '38px'}} 
                            onChange={handleDateChange}
                            format="DD-MM-YYYY"
                            allowClear
                        />
                    </Col>
                </Row>

                <Spin tip="Fetching Data From Server, Please Wait ..." spinning={loading} size="large">
                    <Table 
                        dataSource={reportData}
                        size='small'
                        bordered={true}
                        columns={columnsNew}
                        rowKey="vch_no"
                        summary={() => (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={4} align="right">
                                        <strong>Total</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4} align="center">
                                        <strong>₹{totalAmount.toFixed(2)}</strong>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        )}
                        pagination={{
                            showSizeChanger: true,
                            position: ['bottomCenter'],
                            pageSizeOptions: ['10', '20', '30'],
                            showTotal: (total, range) => (
                                <div style={{fontFamily: 'Poppins', fontSize: '15px'}}>
                                    <strong>{range[0].toLocaleString()}</strong> to <strong>{range[1].toLocaleString()}</strong> of <strong>{total.toLocaleString()}</strong> records
                                </div>
                            )
                        }}
                        style={{fontFamily: 'Poppins'}}
                    />
                </Spin>
            </div>
        </>
    );
};

export default SaleReport;