import React, { useEffect, useState } from 'react';
import { Upload,Button, Checkbox, Col, Input, Row, notification } from 'antd';
import { UploadOutlined,CheckCircleFilled, WarningOutlined } from '@ant-design/icons';
import CostCenterImage from '../../assets/images/cost-center.svg';
import '../../css/container.css';
import '../../css/SmallComponents.css';
import httpCall from '../../utils/api';
import { useFormik } from 'formik';
import costCenterFormFields from './costCenterFormFields';
import PageTitle from '../common/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';

const CostCenterForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (location?.state?.id) {
      httpCall({
        method: 'GET',
        url: '/api/costcenter/get?id=' + location?.state?.id,
      })
        .then((response) => {
          const data = response.data[0];
          formik.setValues(data);
  
          // Set the existing document
          if (data.document_id) {
            setFileList([{
              uid: data.document_id,
              name: data.document_name,
              status: 'done',
              url: data.document_path,
            }]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [location?.state?.id]);
  

  const openNotification = (description) => {
    api.open({
      message: 'Success!',
      duration: 2,
      description: description,
      icon: <CheckCircleFilled style={{ color: '#808000' }} />,
    });
  };

  const openErrorNotification = (description) => {
    api.error({
      message: 'Error!',
      duration: 4,
      description: description,
    });
  };

  const checkDuplicateEntry = async (values) => {
    try {
      const response = await httpCall({
        method: 'POST',
        url: '/api/costcenter/check-duplicate',
        payload: {
          phone: values.phone,
          cost_center_name: values.cost_center_name
        },
      });
      return response.isDuplicate;
    } catch (error) {
      console.error('Error checking for duplicate:', error);
      return false;
    }
  };

  const createCostCenter = async (values, handleReset) => {
    try {
      const formData = new FormData();
      
      // Append all form values
      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      });
      
      // Append file if exists
      if (fileList.length > 0) {
        formData.append('document', fileList[0].originFileObj);
      }

      const response = await httpCall({
        method: 'POST',
        url: '/api/costcenter/create',
        payload: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.message) {
        openNotification('Cost Center Created Successfully');
        handleReset();
        setFileList([]); // Reset file list
      }
    } catch (error) {
      console.error(error);
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      openErrorNotification(errorMessage);
    }
  };

  const updateCostCenter = async (values) => {
    try {
      const formData = new FormData();
  
      // Append all form values
      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      });
  
      // Append the ID
      formData.append('id', location?.state?.id);
  
      // Append file if exists
      if (fileList.length > 0) {
        formData.append('document', fileList[0].originFileObj);
      }
  
      const response = await httpCall({
        method: 'PUT',
        url: '/api/costcenter/edit',
        payload: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.data.success) {
        openNotification('Cost Center Details Updated Successfully');
        setTimeout(() => navigate('/slj/cost-center'), 2500);
      }
    } catch (error) {
      console.error('Error Updating Cost Center:', error);
      const errorMessage = error.response?.data?.error || 'An unexpected error occurred.';
      api.error({
        message: "Error Updating Cost Center",
        description: errorMessage,
        duration: 4,
      });
    }
  };
  

  const formik = useFormik({
    initialValues: {
      cost_center_name: '',
      address_1: '',
      address_2: '',
      phone: '',
      emergency_contact_no: '',
      email: '',
      pan_card_no: '',
      active: false,
      bank_details_1: '',
      account_details_1: 0,
      bank_details_1_branch_details: '',
      bank_details_1_ifsc_code: '',
      prefix_no: '',
    },
    validate: (values) => {
      const errors = {};

      if (!values.cost_center_name) {
        errors.cost_center_name = 'Cost center name is required';
      }
      if (!/^\d{10}$/.test(values.emergency_contact_no)) {
        errors.emergency_contact_no = 'Emergency Contact No. must be exactly 10 digits';
      }
      if (!values.phone) {
        errors.phone = 'Phone number is required';
      } else if (!/^\d{10}$/.test(values.phone)) {
        errors.phone = 'Phone number must be exactly 10 digits';
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      if (values.cost_center_name === '' || values.phone === '') {
        openErrorNotification('Please Enter Required Fields...');
        return;
      }
      if (location?.state?.id) {
        updateCostCenter(values);
      } else {
        createCostCenter(values, resetForm);
      }
    },
  });

  const handleFileChange = (info) => {
    let newFileList = [...info.fileList];
    
    // Limit to one file
    newFileList = newFileList.slice(-1);
    
    // Update status
    newFileList = newFileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    
    setFileList(newFileList);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const isValidType = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/png'
      ].includes(file.type);
  
      if (!isValidType) {
        notification.error({
          message: 'Invalid file type',
          description: 'Please upload a PDF, DOC, DOCX, JPG, or PNG file.',
        });
      }
  
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        notification.error({
          message: 'File too large',
          description: 'File must be smaller than 5MB.',
        });
      }
  
      return isValidType && isLt5M;
    },
    onChange: handleFileChange,
    fileList,
    defaultFileList: fileList,
  };
  
  const documentUploadSection = (
    <Row gutter={16} className="gutter-row">
      <Col span={12} className="gutter-box">
        <div>
          <span className="hintText">Upload Document</span>
        </div>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
        <div style={{ marginTop: '8px', fontSize: '12px', color: '#666' }}>
          Supported formats: PDF, DOC, DOCX, JPG, PNG (max: 5MB)
        </div>
      </Col>
    </Row>
  );


  const createNewFormSection = (sections) => {
    return (
      <>
        {sections.map((section, index) => (
          <Row key={index} gutter={16} className="gutter-row">
            {section.map((field, index) => (
              <Col span={field.colSpan} className="gutter-box" key={index}>
                <div>
                  <span className="hintText">{field.label}</span>
                  {field.isRequired && <span className="mandatory"></span>}
                </div>
  
                {field.type === 'text' || field.type === 'tel' ? (
                  <Input
                    type={field.id === 'phone' ? 'tel' : field.type}
                    id={field.id}
                    style={{ width: '100%', height: '40px' }}
                    onChange={formik.handleChange}
                    value={formik.values[field.id]}
                  />
                ) : field.type === 'select' ? (
                  <select
                    id={field.id}
                    style={{ width: '100%', height: '40px' }}
                    onChange={formik.handleChange}
                    value={formik.values[field.id]}
                  >
                    {field.options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : field.type === 'file' ? (
                  <Input
                    type="file"
                    id={field.id}
                    style={{ width: '100%', height: '40px' }}
                    onChange={formik.handleChange}
                  />
                ) : (
                  <Checkbox
                    id={field.id}
                    style={{ width: '100%', height: '40px' }}
                    onChange={formik.handleChange}
                    checked={formik.values[field.id]}
                  />
                )}
  
                {formik.touched[field.id] && formik.errors[field.id] ? (
                  <div>
                    <WarningOutlined style={{ color: 'red' }} />
                    <span
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: 'red',
                        paddingLeft: '5px',
                      }}
                    >
                      {formik.errors[field.id]}
                    </span>
                  </div>
                ) : null}
              </Col>
            ))}
          </Row>
        ))}
      </>
    );
  };
  
  return (
    <>
      <PageTitle
        imagesrc={CostCenterImage}
        pageTitle={
          location?.state?.id ? "Update Cost Center" : "Create Cost Center"
        }
        buttonList={[
          {
            buttonName: "Back",
            className: "btn-back",
            url: "/slj/cost-center",
          },
        ]}
      />

      {contextHolder}
      <div className="content-area">
        <div className="formAreaStyle">
          {createNewFormSection(costCenterFormFields.sections)}
          {documentUploadSection}
        </div>
      </div>
      <div className="formSubmitArea">
        <div className="formSubmitInnerArea">
          <Button
            type="primary"
            size="large"
            style={{ width: "170px", backgroundColor: "grey" }}
            onClick={formik.handleReset}
          >
            Reset Fields
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ width: "170px", backgroundColor: "#1f43e5" }}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default CostCenterForm;